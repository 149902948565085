import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  ConnectionQuality,
  createLocalAudioTrack,
  createLocalTracks,
  createLocalVideoTrack,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
} from "livekit-client";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  IconButton,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import {
  Mic,
  Videocam,
  MicOff,
  VideocamOff,
  VolumeUp,
} from "@mui/icons-material";
import "./DeviceTestPage.scss";
import { appJotaiStore } from "../../app-jotai";
import {
  currentSessionAtom,
  deviceManagerAtom,
  Session,
  updateAudioDevices,
  updateAudioOutputDevices,
  updateSelectedAudioInputDevice,
  updateSelectedAudioOutputDevice,
  updateSelectedVideoInputDevice,
  updateVideoDevices,
} from "../../store/session";
import { fetchSessionState } from "../../api";
import { enqueueSnackbar } from "notistack";
import { useAtom, useAtomValue } from "jotai";
import { useParams } from "react-router-dom";
import { getToken, getTokenClaims, setToken } from "../../utils/apicall";
import { getRoomDetails } from "../../api/room";
import { getUserDetails, registerUser } from "../../api/user";
import { ParticipantRole } from "../../types/livekit";
import {  useHandleAppTheme } from "../../useHandleAppTheme";
const rooms = [
  { name: "Room 1", id: "room1" },
  { name: "Room 2", id: "room2" },
  { name: "Room 3", id: "room3" },
  { name: "Room 4", id: "room4" },
  { name: "Room 5", id: "room5" },
  { name: "Room 6", id: "room6" },
  { name: "Room 7", id: "room7" },
  { name: "Room 8", id: "room8" },
];


const DeviceTestPage = () => {
  const [loaded, setLoaded] = useState(false);
  const { roomId } = useParams<{ roomId: string }>();
  const deviceManger = useAtomValue(deviceManagerAtom);

  const selectedAudioInput = deviceManger.selectedAudioInput;
  const selectedVideoInput = deviceManger.selectedVideoInput;
  const selectedAudioOutput = deviceManger.selectedAudioOutput;

  const audioInputs = deviceManger.audioDevices;
  const audioOutputs = deviceManger.audioOutputDevices;
  const videoInputs = deviceManger.videoDevices;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [roomData, setRoomData] = useState<any>();
  const [roomTitle, setRoomTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [audioPermissionGranted, setAudioPermissionGranted] = useState<
    boolean | null
  >(null);
  const [videoPermissionGranted, setVideoPermissionGranted] = useState<
    boolean | null
  >(null);

  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack | null>(null);
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack | null>(null);

  const [audioMuted, setAudioMuted] = useState<boolean>(false);
  const [videoMuted, setVideoMuted] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { editorTheme } = useHandleAppTheme();

  useEffect(() => {
    const audioInput = localStorage.getItem("selectedAudioInput") ?? "";
    const videoInput = localStorage.getItem("selectedVideoInput") ?? "";
    const audioOutput = localStorage.getItem("selectedAudioOutput") ?? "";

    updateSelectedAudioInputDevice(audioInput);
    updateSelectedAudioOutputDevice(audioOutput);
    updateSelectedVideoInputDevice(videoInput);

    // get room Details
    if (roomId) {
      // console.log("calling class api ", import.meta);
      getRoomDetails(roomId).then((data) => {
        console.log("room details from database...", data);
        setRoomData(data);
        setRoomTitle(data.title);
        setDescription(data.description);
      });

      // setRoomTitle("Room from Database")
    }

    if (getToken(true)) {
      const userId = getTokenClaims().userId;
      console.log("user id from token", userId);
      setUserId(userId);
      getUserDetails(userId).then((data) => {
        console.log("user details from database...", data);
        setName(data.name);
      });

    }

    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;

    localStorage.setItem("selectedAudioInput", selectedAudioInput);
  }, [selectedAudioInput]);

  useEffect(() => {
    if (!loaded) return;

    localStorage.setItem("selectedAudioOutput", selectedAudioOutput);
  }, [selectedAudioOutput]);

  useEffect(() => {
    if (!loaded) return;
    localStorage.setItem("selectedVideoInput", selectedVideoInput);
  }, [selectedVideoInput]);

  useEffect(() => {
    if (!loaded) return;

    const fetchDevices = async () => {
      try {
        const devices = await Room.getLocalDevices("videoinput");

        updateVideoDevices(devices);
        console.log("selected video input", selectedVideoInput);

        if (selectedVideoInput == "") {
          console.log("video ooutput device...", devices[0].deviceId);
          updateSelectedVideoInputDevice(devices[0].deviceId);
        }

        // appJotaiStore.set(currentSessionAtom,{...session,videoDevices:devices});

        console.log("video devices update hua...", devices);

        const videoTrack = await createLocalVideoTrack({
          deviceId: selectedVideoInput,
        });
        setVideoPermissionGranted(true);
        setVideoTrack(videoTrack);
      } catch (err) {
        setVideoPermissionGranted(false);
      }
    };

    fetchDevices();


  }, [loaded]);

  useEffect(() => {
    if (!loaded) return;

    const fetchDevices = async () => {
      try {
        const audioDevices = await Room.getLocalDevices("audioinput");

        updateAudioDevices(audioDevices);
        if (!selectedAudioInput) {
          updateSelectedAudioInputDevice(audioDevices[0].deviceId);
        }
        // setSelectedAudioInput(audioDevices[0].deviceId);
        const audioTrack = await createLocalAudioTrack({
          deviceId: selectedAudioInput,
        });
        setAudioPermissionGranted(true);
        setAudioTrack(audioTrack);
      } catch (err) {
        setAudioPermissionGranted(false);
      }
    };
    fetchDevices();
  }, [loaded]);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const audioOutput = await Room.getLocalDevices("audiooutput");
        updateAudioOutputDevices(audioOutput);
        if (!selectedAudioOutput) {
          updateSelectedAudioOutputDevice(audioOutput[0].deviceId);
        }
        // updateSelectedAudioOutputDevice(audioOutput[0].deviceId);
      } catch (err) {
        // setVideoPermissionGranted(false);
      }
    };
    if (loaded) fetchDevices();
  }, [loaded]);

  // Attach video track to video element
  useEffect(() => {
    if (videoTrack && videoRef.current) {
      videoTrack.attach(videoRef.current);
    }

    return () => {
      if (videoTrack && videoRef.current) {
        videoTrack?.detach();
        videoTrack?.stop();
        videoRef.current.srcObject = null;
      }
    };
  }, [videoTrack]);

  const toggleAudio = useCallback(() => {
    setAudioMuted((prevMuted) => !prevMuted);
    if (audioTrack) {
      audioTrack.isMuted ? audioTrack.unmute() : audioTrack.mute();
    }
  }, [audioMuted, audioTrack]);

  useEffect(() => {
    const updateVideoTrack = async () => {
      if (selectedVideoInput) {
        if (videoTrack) {
          console.log("video device input changed.. ", selectedVideoInput);
          videoTrack.setDeviceId(selectedVideoInput);
        }
      }
    };
    updateVideoTrack();
  }, [selectedVideoInput]); 

  const toggleVideo = useCallback(() => {
    setVideoMuted((prevMuted) => !prevMuted);
    if (videoTrack) {
      videoTrack.isMuted ? videoTrack.unmute() : videoTrack.mute();
    }
  }, [videoMuted, videoTrack]);

  useEffect(() => {
    return () => {
      if (audioTrack) {
        audioTrack.stop();
      }
    };
  }, []);

  const testSpeaker = () => {
    const audio = new Audio("/test-audio.mp3");
    if (audio.setSinkId && selectedAudioOutput) {
      audio
        .setSinkId(selectedAudioOutput)
        .then(() => {
          audio.play();
        })
        .catch((err) => {
          console.error(err);
          enqueueSnackbar("Unable to play test audio.", { variant: "error" });
        });
    } else {
      audio.play();
    }
  };

  const handleContinue = async () => {
    if (!name || !roomId) {
      return;
    }

    if(!userId) {
      // generate userId first and then continue
      await registerUser(name,email).then((data) => {
        console.log("user registered...", data);
        setToken(data.access_token);
        setUserId(data.userId);
        console.log("set user chal gya...")
      })
    }


    const sessionData = await fetchSessionState(roomId, userId);

    console.log("got session from database..", sessionData);

    const session:Session = {
      roomId:roomId,
      title:roomTitle,
      token:sessionData.token,
      livekitUrl:sessionData.livekitUrl,
      mqttUrl:sessionData.mqttUrl,
      duration:roomData.duration,
      subRoomId:roomData.subClassId,
      me:{
        userId:userId,
        name:name,
        image:"",
        isLocal:true,
        isPresenter:true,
        role: getTokenClaims().role as ParticipantRole,
        connectionQuality: ConnectionQuality.Unknown,

        isOnline:true,
        audioEnabled:!audioMuted,
        videoEnabled:!videoMuted,
        controls:{
          write:true,
          audio:true,
          video:true,
          chat:true,
          screen:true
        }
      }
    }
    videoTrack?.detach();
    videoTrack?.stop();
    appJotaiStore.set(currentSessionAtom, session);


   

  };

  const requestPermissions = async () => {
    try {
      const tracks = await createLocalTracks({
        audio: {
          deviceId: selectedAudioInput,
        },
        video: {
          deviceId: selectedVideoInput,
        },
      });
      setAudioTrack(
        tracks.find((track) => track.kind === "audio") as LocalAudioTrack,
      );
      setVideoTrack(
        tracks.find((track) => track.kind === "video") as LocalVideoTrack,
      );
      setAudioPermissionGranted(true);
      setVideoPermissionGranted(true);
    } catch (err) {
      setAudioPermissionGranted(false);
      setVideoPermissionGranted(false);
    }
  };





  return (
    <div className={`excalidraw device-test-page ${editorTheme == "dark" ? "theme--dark":"theme--light"}`}>
      <div className="test-box">
        {/* Left Column: Device Test */}
        <div className="left-column">
          <div className="video-container">
            <div className="video-box avatar-placeholder">
              {videoTrack ? (
                <video
                  autoPlay
                  muted={audioMuted}
                  ref={videoRef}
                  className={`video-element ${videoMuted ? "video-muted" : ""}`}
                />
              ) : (
                <div className="avatar-placeholder" />
              )}
              <div className="video-controls">
                <IconButton
                  onClick={toggleAudio}
                  className="transparent-button"
                >
                  {audioMuted ? <MicOff /> : <Mic />}
                </IconButton>
                <IconButton
                  onClick={toggleVideo}
                  className="transparent-button"
                >
                  {videoMuted ? <VideocamOff /> : <Videocam />}
                </IconButton>
              </div>
            </div>
            <div className="device-selection">
              {/* Audio Input */}
              <FormControl
                size="small"
                fullWidth
                error={!audioPermissionGranted}
              >
                <Select
                  value={selectedAudioInput}
                  onChange={(e) =>
                    updateSelectedAudioInputDevice(e.target.value)
                  }
                  input={
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <Mic />
                        </InputAdornment>
                      }
                    />
                  }
                >
                  {audioPermissionGranted ? (
                    audioInputs.map((input) => (
                      <MenuItem key={input.deviceId} value={input.deviceId}>
                        {input.label || `Microphone ${input.deviceId}`}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="" disabled>
                      Microphone access denied
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

              {/* Video Input */}
              <FormControl
                fullWidth
                size="small"
                error={!videoPermissionGranted}
              >
                <Select
                  value={selectedVideoInput}
                  onChange={(e) =>
                    updateSelectedVideoInputDevice(e.target.value)
                  }
                  input={
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <Videocam />
                        </InputAdornment>
                      }
                    />
                  }
                >
                  {videoPermissionGranted ? (
                    videoInputs.map((input) => (
                      <MenuItem key={input.deviceId} value={input.deviceId}>
                        {input.label || `Camera ${input.deviceId}`}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem selected value="" disabled>
                      Camera access denied
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

              {/* Audio Output */}
              <FormControl fullWidth size="small">
                <Select
                  value={selectedAudioOutput}
                  onChange={(e) =>
                    updateSelectedAudioOutputDevice(e.target.value)
                  }
                  input={
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <VolumeUp />
                        </InputAdornment>
                      }
                    />
                  }
                >
                  {audioOutputs.map((output) => (
                    <MenuItem key={output.deviceId} value={output.deviceId}>
                      {output.label || `Speakers ${output.deviceId}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="outlined"
                onClick={() => console.log("Test Speakers")}
                startIcon={<VolumeUp />}
              >
                Test Speakers
              </Button>
            </div>
          </div>
        </div>

        {/* Right Column: Name, Room and Continue Button */}
        <div className="right-column">
          <div className="name-room-container">
            {userId ? (
              <div className="header-text">
                <span style={{ fontSize: "30px" }}>Welcome {name}</span>
              </div>
            ) : (
              <>
               <TextField
                variant="outlined"
                placeholder="Your Name"
                fullWidth
                value={name}
                size="small"
                onChange={(e) => setName(e.target.value)}
              />
               <TextField
                variant="outlined"
                placeholder="Your Email"
                fullWidth
                value={email}
                size="small"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              </>
             

              
            )}

            {roomId ? (
              <div className="header-text">
                <span style={{ fontSize: "20px" }}>Session: {roomTitle}</span>
               <div>
                <span style={{fontSize:"15px"}}>{description}</span>
               </div>
              </div>
            ) : (
              <Select
                value={roomId}
                // onChange={(e) => setRoomId(e.target.value)}
                size="small"
              >
                {rooms.map((output) => (
                  <MenuItem key={output.id} value={output.id}>
                    {output.name}
                  </MenuItem>
                ))}
              </Select>
            )}

            <Button
              onClick={handleContinue}
              variant="outlined"
              color="primary"
              fullWidth
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceTestPage;
