import { IconButton } from "@mui/material";

import PresentToAllIcon from "@mui/icons-material/PresentToAll";
import { createLocalScreenTracks } from "livekit-client";
import { useEffect, useState } from "react";
import { getMediaServerConnRoom } from "../../../utils/livekitClient";
import React from "react";
import Collab, { getCollab } from "../../whiteboard/sync";
import { useAtomValue } from "jotai";

export const ScreenShareIcon = () => {
  const [loading, setLoading] = useState(false);
  const currentRoom = getMediaServerConnRoom();
  // const currentSession = useAtomValue(currentSessionAtom)

  // const [collab,setCollab] = useState<Collab | null>(null);
  // const collab = getCollab();

 
  const shareScreen = async () => {
    setLoading(true);

    try {
      const tracks = await createLocalScreenTracks();
      for (let i = 0; i < tracks.length; i++) {
        await currentRoom.localParticipant.publishTrack(tracks[i]);
      }

      // const tabMeta: TabMetaData = {
      //   tabId: currentSession.meId,
      //   type:"screen",
      //   title:currentSession.name +" Screen"
      // }
      
      // addTab(tabMeta);
      // const tab: Tab = {
      //   type: tabMeta.type,
      //   // pages: [page],
      //   // index: 0,
      //   tabId: tabMeta.tabId,
      // };
    

      // Tabs.set(tabMeta.tabId,tab)
      // collab.createTab(tabMeta);
      // setCurrentTab(tabMeta);
      // collab.switchTab(tabMeta);

    } catch (error) {
      console.log("error while screen sharing..", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <IconButton
      style={{
        // border:"1px solid #ccc",
        // background: "#ccc",
        marginLeft: "5px",
      }}
      disabled={loading}
      size="large"
      onClick={shareScreen}
    >
      <PresentToAllIcon />
    </IconButton>
  );
};
