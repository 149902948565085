// src/api/index.ts

import axios from "axios";
import { Participant } from "../types/livekit";
import { appJotaiStore } from "../app-jotai";
import { participantLoadingAtom } from "../store/participantList";
import { Session } from "../store/session";
import { CONTENT_API, ROOM_API } from "../constants/api";
// import { WhiteboardData, UserSettings } from '../types';

// const API_BASE_URL = 'https://your-api-url.com';

// export const fetchWhiteboardData = async (roomId: string): Promise<WhiteboardData> => {
//   const response = await axios.get(`${API_BASE_URL}/whiteboard/${roomId}`);
//   return response.data;
// };

// export const fetchUserSettings = async (userId: string): Promise<UserSettings> => {
//   const response = await axios.get(`${API_BASE_URL}/users/${userId}/settings`);
//   return response.data;
// };

export const fetchLivekitToken = async (
  roomId: string,
  userId: string,
): Promise<string> => {
  const response = await axios.get(
    `https://live.merithub.com/room/avtoken/networkID/${roomId}/${userId}`,
  );
  
  return response.data.token;
};

export const fetchSessionState = async (roomId:string, userId:string) => {
  const response  = await axios.get(`${ROOM_API}/classroom/${roomId}/state/${userId}`);
  
  console.log("session details.. ",response.data);
  return response.data.data as Session;
}

export const fetchParticipants = async (roomId:string):Promise<Participant[]> => {

  try {
    const response  = await axios.get(`${ROOM_API}/classroom/${roomId}/participants`);
    console.log("participnats...",response.data)
    return response.data.participants as Participant[];
  } catch (error) {
    throw new Error("Error while fetching participants list");
  } 
}

export const getPresignedUrl = async (userId: string, fileName: string, fileType:string) => {

  // post request to get presigned url
  const response = await axios.post(`${CONTENT_API}/presignedUrl`, {
    fileName:fileName,
    fileType:fileType
  });

  console.log("response from presigned url endpoint...", response);
  // const response = await axios.get(`${ROOM_API}/classroom/${roomId}/presigned-url/${userId}/${fileName}`);
  return response.data;
}

export const requestFileConversion = async (userId: string, fileId: string,fileName:string, fileType:string, url:string) => {
  const response = await axios.post(`${CONTENT_API}/addFile`, {
    fileId:fileId,
    type:fileType,
    userId:userId,
    title:fileName,
    bucket:"test.simpech.com",
    url:url,
    // "status":"pending"
  });

  console.log("response from convert endpoint...", response);
  return response.data;
}

export const fetchConvertedFiles = async (fileId: string) => {
  const response = await axios.get(`${CONTENT_API}/files/${fileId}`);
  return response.data;
}

export const fetchRecentFiles = async () => {
  const response = await axios.get(`${CONTENT_API}/files`);
  return response.data;
}