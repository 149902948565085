import {
  IMAGE_MIME_TYPES,
  MIME_TYPES,
} from "../../../../packages/excalidraw/constants";
import { compressData, decompressData } from "../../../../packages/excalidraw/data/encode";
import type { FileId } from "../../../../packages/excalidraw/element/types";
import { ExcalidrawElement } from "../../../../packages/excalidraw/element/types";
import type {
  BinaryFileData,
  BinaryFileMetadata,
  DataURL,
} from "../../../../packages/excalidraw/types";
// import * as AWS from "aws-sdk";
import { FILE_CACHE_MAX_AGE_SEC } from "../../../app_constants";
import type { SyncableExcalidrawElement } from ".";
import { getSyncableElements } from ".";
import axios from "axios";
import { restoreElements } from "../../../../packages/excalidraw";

import { CONTENT_API, ROOM_API } from "../../../constants/api";
import { ValueOf } from "../../../../packages/excalidraw/utility-types";
import { Tab } from "../../../types/tabs";
import { tabPoints } from "../../../store/tabList";

export const loadFilesFromStorage = async (
  prefix: string,
  decryptionKey: string,
  filesIds: readonly FileId[],
) => {
  const loadedFiles: BinaryFileData[] = [];
  const erroredFiles = new Map<FileId, true>();

  await Promise.all(
    [...new Set(filesIds)].map(async (id) => {
      try {
        console.log("getting files from storage ... id...", id);
        const resp = await fetch(`${CONTENT_API}/images/${id}`);

        const data = await resp.json();

        const imageData = await fetch(data.url + "?wb=1");
        const blob = await imageData.blob();

        // console.log("myme tyellel",blob.type);

        // Wrap FileReader in a promise
        await new Promise<void>((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            loadedFiles.push({
              mimeType: blob.type as ValueOf<typeof IMAGE_MIME_TYPES>,
              id,
              dataURL: reader.result as DataURL,
              created: Date.now(),
              lastRetrieved: Date.now(),
            });
            resolve();
          };
          // reader.onload = () => resolve(reader.result as string);
          reader.onerror = reject;
        });

        // Load image and ensure it completes before proceeding
        // await new Promise<void>((resolve, reject) => {
        //   const image = new Image();
        //   image.src = imgData;
        //   image.onload = () => {
        //     console.log("image loaded...");
        //     loadedFiles.push({
        //       mimeType: MIME_TYPES.png,
        //       id,
        //       dataURL: imgData as DataURL,
        //       created: Date.now(),
        //       lastRetrieved: Date.now(),
        //     });
        //     resolve();
        //   };
        //   image.onerror = reject;
        // });
      } catch (error: any) {
        erroredFiles.set(id, true);
        console.error(error);
      }
    }),
  );

  // console.log("loaded files... returning", loadedFiles);
  return { loadedFiles, erroredFiles };
};

export const saveFilesToStorage = async ({
  files,
}: {
  files: Map<FileId, BinaryFileData>;
}) => {
  // const firebase = await loadFirebaseStorage();

  const erroredFiles = new Map<FileId, true>();
  const savedFiles = new Map<FileId, true>();

  // console.log("save hone aayah hahaiaiiaiai");
  await Promise.all(
    Array.from(files).map(async ([id, fileData]) => {
      try {
        if (id.includes("_file")) {
          savedFiles.set(id, true);
          return;
        }

        const response = await axios.post(
          `${CONTENT_API}/images`,
          {
            fileId: id,
            data: fileData.dataURL,
            type: fileData.mimeType,
            title: fileData.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
        // console.log("response from upload endpoint...", response);

        savedFiles.set(id, true);
      } catch (error: any) {
        console.error(error);
        erroredFiles.set(id, true);
      }
    }),
  );

  return { savedFiles, erroredFiles };
};


export const loadFromBackend = async (
  roomId: string,
): Promise<{ tabs: Tab[]; currentTabIndex: number }> => {
  const tabMeta: Tab = {
    type: "whiteboard",
    title: "Board 1",
    tabId: "default-tab-id",
  };
  let tabs: Tab[] = [];
  let currentTabIndex = 0;

  try {
    const resp = await axios.get(`${ROOM_API}/classroom/${roomId}/whiteboard`);
    console.log("resp from whiteboard endpoint..", resp);

    const backendData = resp.data.data;

    if (backendData) {
      backendData.forEach((tab: { title: string; active: any; points: readonly ExcalidrawElement[] | null | undefined; tabId: string; type: any; }, index: number) => {
        if (!tab.title) {
          tab.title = `Board ${index + 1}`;
        }

        if (tab.active) {
          currentTabIndex = index;
        }

        // Log raw points data from backend
        console.log("raw tab points from backend:", tab.points);

        // Try restoring and synchronizing points
        const restoredElements = restoreElements(tab.points, null);

        const points = getSyncableElements(restoredElements);
       
        console.log("added points in restored points from backend:", tab.tabId);
        tabPoints.set(tab.tabId, points);

        // tab.points = points;
        let tabMetadata: Tab = {
          title: tab.title,
          type: tab.type,
          tabId: tab.tabId,
        };

        // tabMetadata.points = points;

        tabs.push(tabMetadata);

        console.log("tab points data from backend..", tabPoints);
      });
    }
  } catch (error) {
    const tab: Tab = {
      type: tabMeta.type,
      tabId: tabMeta.tabId,
      title: tabMeta.title,
    };
    tabs.push(tab);
  }

  // Return the processed tabs and currentTabIndex after the forEach loop
  console.warn("final tabs from db..", tabs);
  return { tabs, currentTabIndex };
};



// let s3: AWS.S3 | null = null;

// const getS3 = (): AWS.S3 => {
//   if (!s3) {
//     console.log("s3 cred created....");
//     s3 = new AWS.S3({
//       accessKeyId: import.meta.env.VITE_APP_AWS_ACCESS_KEY, // Replace with your access key
//       secretAccessKey:
//       import.meta.env.VITE_APP_AWS_SECRET_ACCESS, // Replace with your secret key
//       endpoint: import.meta.env.VITE_APP_AWS_S3_ENDPOINT, // Replace with your S3-compatible storage endpoint
//       region: import.meta.env.VITE_APP_AWS_REGION, // Replace with your region if necessary
//       // s3ForcePathStyle: true, // Required for some S3-compatible services
//       // signatureVersion: "v4", // Signature version for S3 (v4 is common, but check your provider's documentation)
//     });
//   }
//   return s3;
// };


export const compressImages = async () => {
  
}