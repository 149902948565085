import React, { useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import {
  isParticipantListVisibleAtom,
  participantListAtom,
} from "../../store/participantList";
import {
  IconButton,
  List,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
} from "@mui/material";

import Close from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SearchIcon from "@mui/icons-material/Search";
import { ParticipantListItem } from "./_components/ParticipantListItem";
import "./ParticipantComponent.scss";

export const ParticipantComponent = () => {
  const [isVisible, setIsVisible] = useAtom(isParticipantListVisibleAtom);
  const participants = useAtomValue(participantListAtom);

  // Invite pop-up state
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [copiedLink, setCopiedLink] = useState(""); // Track copied state

  // Function to copy the link to the clipboard
  const handleCopy = (link: string) => {
    navigator.clipboard.writeText(link);
    setCopiedLink(link);
    setTimeout(() => {
      setCopiedLink(""); // Clear copied state after 1 seconds
    }, 1000);
  };

  return (
    <>
      <div className={`participant-container ${isVisible ? "visible" : ""}`}>
        <div className="participant-header">
          <h2>Participants</h2>
          <IconButton onClick={() => setIsVisible(false)}>
            <Close />
          </IconButton>
        </div>

        {/* Search Bar with Invite Button */}
        <div className="search-invite-wrapper">
          <TextField
            className="search-bar"
            variant="outlined"
            placeholder="Search participants..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            className="invite-button"
            variant="contained"
            color="primary"
            onClick={() => setInviteDialogOpen(true)}
          >
            Invite
          </Button>
        </div>

        <List>
          {Array.from(participants.values()).map((participant) => (
            <ParticipantListItem key={participant.userId} participant={participant} />
          ))}
        </List>
      </div>

      {isVisible && (
        <div className="overlay" onClick={() => setIsVisible(false)}></div>
      )}

      {/* Invite Dialog */}
      <Dialog
        open={inviteDialogOpen}
        onClose={() => setInviteDialogOpen(false)}
        maxWidth="sm" // Set the maximum width
        fullWidth // Allow the dialog to take full width
      >
        <DialogTitle>Invitation Links</DialogTitle>
        <DialogContent>
          <div className="invite-links">
            <div className="invite-section">
              <h3>Instructor Link</h3>
              <div className="link-wrapper">
                <span className="link-text">https://random-instructor-link.com</span>
                <IconButton
                  className={`copy-button ${copiedLink === "https://random-instructor-link.com" ? "clicked" : ""}`}
                  onClick={() => handleCopy("https://random-instructor-link.com")}
                >
                  <ContentCopyIcon />
                </IconButton>
              </div>
            </div>

            <div className="invite-section">
              <h3>Learner Link</h3>
              <div className="link-wrapper">
                <span className="link-text">https://random-learner-link.com</span>
                <IconButton
                  className={`copy-button ${copiedLink === "https://random-learner-link.com" ? "clicked" : ""}`}
                  onClick={() => handleCopy("https://random-learner-link.com")}
                >
                  <ContentCopyIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInviteDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
