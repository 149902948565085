import { atom } from "jotai";
import { Tab } from "../types/tabs";
import { appJotaiStore } from "../app-jotai";
import { OrderedExcalidrawElement } from "../../packages/excalidraw/element/types";

export const tabListAtom = atom<Tab[]>([]); // Changed from Map to Array

export const tabPoints = new Map<string, OrderedExcalidrawElement[]>();

export const currentTabAtom = atom<Tab | null>(null);
export const whiteboardLoadingAtom = atom<boolean>(false);

// Function to add a new tab to the array
export const addTab = (tab: Tab) => {
  console.log("tab added... ", tab);
  appJotaiStore.set(tabListAtom, (prev) => [...prev, tab]);
};

// Function to update a tab by its id
export const updateTab = (id: string, changes: Partial<Tab>) => {
  appJotaiStore.set(tabListAtom, (prev) => {
    return prev.map((tab) => (tab.tabId === id ? { ...tab, ...changes } : tab));
  });

  // Check if the updated tab is the current tab
  const currentTab = appJotaiStore.get(currentTabAtom);
  if (currentTab && currentTab.tabId === id) {
    appJotaiStore.set(currentTabAtom, { ...currentTab, ...changes });
  }
};

// Function to add multiple tabs
export const addTabs = (tabs: Tab[]) => {
  appJotaiStore.set(tabListAtom, (prev) => [...prev, ...tabs]);
};

// Function to set the current tab
export const setCurrentTab = (newTab: Tab) => {
  appJotaiStore.set(currentTabAtom, newTab);

  // Update the tab in tabListAtom as well
  appJotaiStore.set(tabListAtom, (prev) => {
    return prev.map((tab) => (tab.tabId === newTab.tabId ? newTab : tab));
  });
};

// Function to remove a tab by its id
export const removeTab = (id: string) => {
  appJotaiStore.set(tabListAtom, (prev) => prev.filter((tab) => tab.tabId !== id));

  // If the current tab is the one being removed, reset currentTabAtom to null
  const currentTab = appJotaiStore.get(currentTabAtom);
  if (currentTab?.tabId === id) {
    appJotaiStore.set(currentTabAtom, null);
  }
};

// Function to get a tab by its id
export const getTab = (id: string) => {
  return appJotaiStore.get(tabListAtom)?.find((tab) => tab.tabId === id);
};
