import React from "react";
import AudioIcon from "./icons/AudioIcon";
import { ScreenShareIcon } from "./icons/ScreenShareIcon";
import VideoIcon from "./icons/VideoIcon";
import PeopleIcon from "./icons/PeopleIcon";
import InfoIcon from "./icons/InfoIcon";
import SettingsIcon from "./icons/SettingsIcon";
import AppsIcon from "./icons/AppsIcon";
import ChatIcon from "./icons/ChatIcon";
import LayoutIcon from "./icons/LayoutIcon";
import ExitIcon from "./icons/ExitIcon";
import RecordIcon from "./icons/RecordIcon";
function ControlsLayout() {
  return (
    <div className="controls-container">
      <div style={{marginLeft:"10px", display:"inline-flex"}}>
        {/* <InfoIcon /> */}
        <AppsIcon />
      </div>
      <div style={{ display:"inline-flex"}}>
        <AudioIcon />
        <VideoIcon />
        <ScreenShareIcon />
        <RecordIcon />
        <LayoutIcon />
        <SettingsIcon />

      </div>

      <div style={{marginRight:"10px", display:"inline-flex"}}>
        <PeopleIcon />
        <ChatIcon />
        <ExitIcon />
      </div>
    </div>
  );
}

export default ControlsLayout;
