import { useAtom, useAtomValue } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { currentSessionAtom, isChatVisibleAtom } from "../../store/session";
import { addMessage, chatListAtom, Message } from "../../store/chat";
import { syncData } from "../../utils/roomClient";
import { getParticipant } from "../../store/participantList";
import "./ChatComponent.scss";

export const ChatComponent = () => {
  const [isVisible, setIsVisible] = useAtom(isChatVisibleAtom);
  const messages = useAtomValue(chatListAtom);
  const [newMessage, setNewMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messageEndRef = useRef<HTMLDivElement | null>(null);
  const currentSession = useAtomValue(currentSessionAtom);

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleSend = () => {
    if (newMessage.trim()) {
      const mes: Message = {
        messageId: Math.random().toString(),
        sender: currentSession?.me.userId!,
        message: newMessage.trim(),
        timestamp: Date.now(),
        type: "text",
      };

      syncData.SendNewMessage(currentSession?.roomId!, currentSession?.me.userId!, mes);
      addMessage(mes);
      setNewMessage("");
    }
  };

  const handleKeyDown = (e:any) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const addEmoji = (emoji: string) => {
    setNewMessage((prev) => prev + emoji);
  };

  return (
    <>
      <div className={`chat-container ${isVisible ? "visible" : ""}`}>
        <div className="chat-header">
          <h2>Chats</h2>
          <IconButton onClick={() => setIsVisible(false)}>
            <Close />
          </IconButton>
        </div>

        <div className="message-container">
          <div className="message-list">
            {messages?.map((msg) => {
              const isOwnMessage = msg.sender === currentSession?.me.userId!;

              return (
                <div
                  key={msg.messageId}
                  className={`message ${isOwnMessage ? "own-message" : "other-message"}`}
                >
                  <div className="message-header">
                    <span className="sender-name">
                      {isOwnMessage ? (currentSession?.me.name + " (me)")! : getParticipant(msg.sender)?.name}
                    </span>
                    <span className="timestamp">
                      {new Date(msg.timestamp).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  </div>
                  <div className="message-content">{msg.message}</div>
                </div>
              );
            })}
            <div ref={messageEndRef} />
          </div>

          <div className="chat-input">
            <IconButton className="emoji-button" onClick={() => setShowEmojiPicker((prev) => !prev)}>
              <InsertEmoticonIcon />
            </IconButton>

            {/* Emoji Picker */}
            <div className={`emoji-picker-box ${showEmojiPicker ? 'show' : ''}`}>
              <button onClick={() => addEmoji("😊")} className="emoji">😊</button>
              <button onClick={() => addEmoji("😂")} className="emoji">😂</button>
              <button onClick={() => addEmoji("😢")} className="emoji">😢</button>
              <button onClick={() => addEmoji("😍")} className="emoji">😍</button>
              <button onClick={() => addEmoji("👍")} className="emoji">👍</button>
              <button onClick={() => addEmoji("🔥")} className="emoji">🔥</button>
              <button onClick={() => addEmoji("🎉")} className="emoji">🎉</button>
              <button onClick={() => addEmoji("✋")} className="emoji">✋</button>
            </div>

            <input
              type="text"
              value={newMessage}
              placeholder="Type a message"
              onChange={(e) => setNewMessage(e.target.value)}
              // size={30}
              height="10px"
              onKeyDown={handleKeyDown}
            />
            <button onClick={handleSend}>Send</button>
          </div>
        </div>
      </div>

      {isVisible && (
        <div className="overlay" onClick={() => setIsVisible(false)}></div>
      )}
    </>
  );
};
