import { AudioPresets, ScreenSharePresets, VideoPresets } from "livekit-client";

export async function getDevices(kind: MediaDeviceKind) {
  let constraints: MediaStreamConstraints = {
    audio: true,
  };
  if (kind === "videoinput") {
    constraints = {
      video: true,
    };
  }
  const stream = await navigator.mediaDevices.getUserMedia(constraints);
  let devices = await navigator.mediaDevices.enumerateDevices();
  devices = devices.filter((device) => device.kind === kind);

  if (devices.length > 1 && devices[0].deviceId === "default") {
    // find another device with matching group id, and move that to 0
    const defaultDevice = devices[0];
    for (let i = 1; i < devices.length; i += 1) {
      if (devices[i].groupId === defaultDevice.groupId) {
        const temp = devices[0];
        devices[0] = devices[i];
        devices[i] = temp;
        break;
      }
    }
    return devices.filter((device) => device !== defaultDevice);
  }

  stream.getTracks().forEach((track) => {
    track.stop();
  });

  return devices;
}

export const getWebcamResolution = (selected: string) => {
  // const selected = (window as any).DEFAULT_WEBCAM_RESOLUTION ?? "h360";
  let resolution = VideoPresets.h360.resolution;

  switch (selected) {
    case "h90":
      resolution = VideoPresets.h90.resolution;
      break;
    case "h180":
      resolution = VideoPresets.h180.resolution;
      break;
    case "h216":
      resolution = VideoPresets.h216.resolution;
      break;
    case "h360":
      resolution = VideoPresets.h360.resolution;
      break;
    case "h540":
      resolution = VideoPresets.h540.resolution;
      break;
    case "h720":
      resolution = VideoPresets.h720.resolution;
      break;
    case "h1080":
      resolution = VideoPresets.h1080.resolution;
      break;
    case "h1440":
      resolution = VideoPresets.h1440.resolution;
      break;
    case "h2160":
      resolution = VideoPresets.h2160.resolution;
      break;
  }

  return resolution;
};

export const getScreenShareResolution = () => {
  const selected =
    (window as any).DEFAULT_SCREEN_SHARE_RESOLUTION ?? "h720fps5";
  let resolution = ScreenSharePresets.h1080fps15.resolution;

  switch (selected) {
    case "h360fps3":
      resolution = ScreenSharePresets.h360fps3.resolution;
      break;
    case "h720fps5":
      resolution = ScreenSharePresets.h720fps5.resolution;
      break;
    case "h720fps15":
      resolution = ScreenSharePresets.h720fps15.resolution;
      break;
    case "h1080fps15":
      resolution = ScreenSharePresets.h1080fps15.resolution;
      break;
    case "h1080fps30":
      resolution = ScreenSharePresets.h1080fps30.resolution;
      break;
  }

  return resolution;
};

export const getAudioPreset = () => {
  const selected = (window as any).DEFAULT_AUDIO_PRESET ?? "music";
  let preset = AudioPresets.music;

  switch (selected) {
    case "telephone":
      preset = AudioPresets.telephone;
      break;
    case "speech":
      preset = AudioPresets.speech;
      break;
    case "music":
      preset = AudioPresets.music;
      break;
    case "musicStereo":
      preset = AudioPresets.musicStereo;
      break;
    case "musicHighQuality":
      preset = AudioPresets.musicHighQuality;
      break;
    case "musicHighQualityStereo":
      preset = AudioPresets.musicHighQualityStereo;
      break;
  }

  return preset;
};
