import { ACCOUNT_API } from "../constants/api";
import { apiCall } from "../utils/apicall";

export const getUserDetails = async (userId: string) => {

    const resp = await apiCall({
        method:"GET",
        baseURL: ACCOUNT_API,
        url:`/users/${userId}/info`
    });

    return resp.data;
}

export const registerUser = async (name:string, email:string) => {
    const resp = await apiCall({
        method:"POST",
        baseURL: ACCOUNT_API,
        url:"/auth/register",
        data:{
            name,
            email
        }
    });

    return resp.data;
}