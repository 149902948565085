import { useAtom, useAtomValue } from "jotai";
import {
  currentSessionAtom,
  isExternalPageModalOpen,
  isExternalVideoModalOpen,
} from "../../../store/session";
import { useState } from "react";
import { isEmpty } from "lodash";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import { Close } from "@mui/icons-material";
import ReactPlayer from "react-player/lazy";
import { syncData } from "../../../utils/roomClient";
import { Tab } from "../../../types/tabs";
import { useHandleAppTheme } from "../../../useHandleAppTheme";

const ExternalPageLinkModal = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(isExternalPageModalOpen);
  const { editorTheme } = useHandleAppTheme();

  const [videoLink, setVideoLink] = useState("");

  const currentSession = useAtomValue(currentSessionAtom);

  const handleClose = () => {
    console.log("close hoa");
    setIsModalOpen(false);
  };

  return (
    <Dialog open={isModalOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent
          className={`excalidraw ${
            editorTheme == "dark" ? "theme--dark" : "theme--light"
          }`}
        >
          <div className="flex flex-col items-center justify-center p-8">
            <h1 className="text-2xl font-bold mb-4">
            Display External Webpage
            </h1>
           

            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              value={videoLink}
              size="small"
              onChange={(e) => setVideoLink(e.target.value)}
              placeholder="Paste the webpage link here"
              style={{
                background: "var(--input-bg-color)",
                borderColor: "var(--input-border-color)",
              }}
            />

            <Button
              variant="contained"
              onClick={()=>{
                if (isEmpty(videoLink)) {
                  console.log("no url");
                  return;
                }
  
               
  
                console.log("created tab.... ");
  
                const tab: Tab = {
                  tabId: currentSession?.me.userId!,
                  type: "webpage",
                  title: "Webpage",
                  url: (videoLink),
                };
                syncData.createTab(
                  currentSession?.roomId!,
                  currentSession?.me.userId!,
                  tab,
                );
  
                syncData.switchTab(
                  currentSession?.roomId!,
                  currentSession?.me.userId!,
                  tab,
                );
  
                handleClose();
              }}
              style={{
                marginTop: 4,
                marginRight: 10,
                color: "var(--color-surface-mid)",
                backgroundColor: "var(--color-on-surface)",
              }}
            >
              Open
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
              style={{
                marginTop: 4,
                color: "var(--primary-color)",
                borderColor: "var(--dialog-border-color)",
                backgroundColor: "var(--island-bg-color)",
              }}
            >
              Close
            </Button>
          </div>
        </DialogContent>
    </Dialog>
  );
};

export { ExternalPageLinkModal };
