import { createRoot } from "react-dom/client";
import App from "./App";

import { SnackbarProvider } from "notistack";

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "jotai";
import { appJotaiStore } from "./app-jotai";
import DeviceTestPage from "./components/device-test";
import AppDemo from "./AppDemo";
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);
root.render(
  <BrowserRouter>
    <Provider unstable_createStore={() => appJotaiStore}>
      <SnackbarProvider
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
      >
        <Routes>
          <Route path="/" element={<AppDemo />} />
          <Route path="/:roomId" element={<App />} />
        </Routes>
      </SnackbarProvider>
    </Provider>
  </BrowserRouter>,
);
