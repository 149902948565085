import type {
  LocalTrackPublication,
  RemoteTrackPublication,
  TrackPublication,
} from "livekit-client";
import React from "react";
import { useEffect, useRef, useState } from "react";

interface VideoElementProp {
  track: TrackPublication | RemoteTrackPublication | LocalTrackPublication;
}

export const VideoElement = ({ track }: VideoElementProp) => {
  const ref = useRef<HTMLVideoElement>(null);
  const [_, setLoaded] = useState<boolean>();

  useEffect(() => {
    const el = ref.current;
    if (el) {
      track.videoTrack?.attach(el);
    }

    return () => {
      if (el) {
        track.videoTrack?.detach(el);
      }
    };
  }, [track]);

  const onLoadedData = () => {
    setLoaded(true);
  };

  return (
    <video
      ref={ref}
      className="participant-video-element"
      autoPlay
      onLoadedData={onLoadedData}
    />
  );
};
