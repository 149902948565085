import { useRef, useEffect } from "react";
import { Tab } from "../../../types/tabs";

const usePreviousTab = (previousTab:Tab | null) => {
    const ref = useRef<Tab | null>(null);
    useEffect(()=>{
      ref.current = previousTab;
    });
  
    return ref.current;
  }



export default usePreviousTab;
