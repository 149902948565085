import React, { useState } from "react";
import "./ExternalWebpage.scss";
import { CircularProgress } from "@mui/material";

interface ExternalWebpageProps {
  src: string;
}

export const ExternalWebpage = ({ src }: ExternalWebpageProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  return (
    <div className="webpage-container">
      {!loaded && (
        <div className="loader-container">
          <div className="loader">
            
            <CircularProgress />
            </div>
        </div>
      )}
      <iframe
        title="External Webpage"
        height="100%"
        width="100%"
        src={src}
        onLoad={() => setLoaded(true)}
        style={{ display: loaded ? "block" : "none" }} // Hide iframe while loading
      />
    </div>
  );
};
