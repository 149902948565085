import React from "react";
import type { Theme } from "../../../../packages/excalidraw/element/types";
import { MainMenu } from "../../../../packages/excalidraw/index";
import { LanguageList } from "../../../app-language/LanguageList";

export const AppMainMenu: React.FC<{
  // theme: Theme | "system";
  // setTheme: (theme: Theme | "system") => void;
}> = React.memo((props) => {
  return (
    <MainMenu>
      <MainMenu.DefaultItems.SaveAsImage />

      <MainMenu.DefaultItems.SearchMenu />
      <MainMenu.DefaultItems.ClearCanvas />
      <MainMenu.Separator />

      {/* <MainMenu.DefaultItems.ToggleTheme
        allowSystemTheme
        theme={props.theme}
        onSelect={props.setTheme}
      /> */}
      <MainMenu.ItemCustom>
        <LanguageList style={{ width: "100%" }} />
      </MainMenu.ItemCustom>
      <MainMenu.DefaultItems.ChangeCanvasBackground />
    </MainMenu>
  );
});
