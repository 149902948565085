import { CLASS_API } from "../constants/api";
import { apiCall } from "../utils/apicall";

export const getRoomDetails = async (roomId: string) => {
  const resp = await apiCall({
    method: "GET",
    baseURL: CLASS_API,
    url: `/classes/${roomId}/info`,
  });

  return resp.data;
};

export const startClass = async (roomId: string, topic:string) => {
  const resp = await apiCall({
    method: "POST",
    baseURL: CLASS_API,
    data:{
        "title": topic
    },
    url: `/classes/${roomId}/start`,
  });

  return resp.data;
};

export const endClass = async (roomId: string) => {
    const resp = await apiCall({
        method: "GET",
        baseURL: CLASS_API,
        url: `/classes/${roomId}/end`,
    });
    
    return resp.data;
}