import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import {jwtDecode} from "jwt-decode";
import { DOMAIN } from '../constants/api';

// Function to retrieve the token from localStorage
const getTokenInternal = (): string | null => {

  // return localStorage.getItem('token');

  return getCookie('token');

};


export function setToken(token: string) {
  // localStorage.setItem("token", token);
  setCookie('token', token);
}

export function getToken(noRedirect:boolean = false) {
  const token = getTokenInternal()  
  if (!token && !noRedirect) {
    window.location.href = "/login";
    return null;
  }
  return token;
}

export function removeToken() {
  // localStorage.removeItem("token");
  removeCookie('token');
}



export function getUserId() {
  const userId = getTokenClaims().userId;
  if (!userId) {
    window.location.href = "/login";
    return null;
  }
  return userId;
}

// export function removeUserId() {
//   localStorage.removeItem("userId");
// }


// API call function with retry capability and custom baseURL
interface ApiCallOptions extends AxiosRequestConfig {
  baseURL?: string; // Allow setting a custom baseURL for each request
  retries?: number;
  retryDelay?: number; // Delay between retries in milliseconds
}

// Create a single axios instance to reuse across the application
const apiClient = axios.create({
  timeout: 10000, // 10 seconds timeout
});

// Request interceptor to add the token to the headers
apiClient.interceptors.request.use(
  (config) => {
    const token = getTokenInternal();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    // config.headers['SM_API_KEY'] = import.meta.env.VITE_SM_API_KEY;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 errors
apiClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
     removeToken();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const apiCall = async <T = any>(
  options: ApiCallOptions
): Promise<AxiosResponse<T>> => {
  const { retries = 3, retryDelay = 1000, ...axiosConfig } = options;

  // Retry mechanism, but only for network errors
  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const response = await apiClient(axiosConfig);
      return response;
    } catch (error) {
      const axiosError = error as AxiosError;

      // Check if the error is a network error or timeout
      const isNetworkError = !axiosError.response && (axiosError.code === 'ECONNABORTED' || axiosError.message === 'Network Error');

      // Only retry for network errors
      if (isNetworkError && attempt < retries - 1) {
        console.warn(`Network error. Retry attempt ${attempt + 1} failed. Retrying...`);
        await new Promise((resolve) => setTimeout(resolve, retryDelay));
      } else {
        // If it's not a network error or retries are exhausted, throw the error
        console.error('Request failed:', error);
        throw error;
      }
    }
  }

  throw new Error('Request failed after all retries');
};



export const getTokenClaims = () => {
  const token = getToken();

  const decodededToken:TokenClaims = jwtDecode(token!);

  // console.log(decodededToken.role);
  return decodededToken
}
export interface TokenClaims {
  role:string;
  status:string;
  userId:string;
  exp:number;
}


// Set a cookie with domain, path, and expiration options
function setCookie(name: string, value: string, days = 7, domain = DOMAIN): void {
  let expires = "";
  if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = `${name}=${encodeURIComponent(value)}${expires}; domain=${domain}; path=/`;
}

// Get a cookie value by name
function getCookie(name: string): string | null {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.startsWith(nameEQ)) {
          return decodeURIComponent(cookie.substring(nameEQ.length));
      }
  }
  return null;
}

// Remove a cookie by setting its expiration date to the past
function removeCookie(name: string, domain = DOMAIN): void {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/`;
}
