import { ExcalidrawElement } from "../../packages/excalidraw/element/types";
import type { Gesture } from "../../packages/excalidraw/types";
import { SyncableExcalidrawElement } from "../components/whiteboard/data";

export enum MqttMessageType {
  SCENE_UPDATE = "scene-update",
  POINTER_UPDATE = "pointer-update",
  WHITEBOARD_APP_STATE_CHANGE = "whiteboard-app-state-change",
  USER_VISIBLE_SCENE_BOUNDS = "user-visible-scene-bounds",
  SWITCH_TAB = "switch-tab",
  CREATE_TAB = "create-tab",
  DELETE_TAB = "delete-tab",
  USER_ONLINE = "user-online",
  USER_OFFLINE = "user-offline",
  USER_TALKED = "user-talked",
  USER_MUTE_STATUS = "user-mute-status",
  USER_CONTROL_STATUS = "user-control-status",
  USER_DISCONNECTED = "user-disconnected",
  USER_METADATA_UPDATE = "user-metadata-update",
  SESSION_ENDED = "session-ended",
  USER_FOLLOW_CHANGE = "user-follow-change",
  PRIVATE = "private",
  PLAYER_STATE = "player-state",
  EDITOR_STATE = "editor-state",
  LAYOUT_CHANGE = "layout-change",
  NEW_MESSAGE = "new-message",
  UPDATE_MESSAGE = "update-message",
  DELETE_MESSAGE = "delete-message",
}

export type MqttMessage = {
  type: MqttMessageType;
  data: string;
  userId: string;
  roomId: string;
  time: number;
  to?: string;
};

export type MqttWhiteboardPointsPayload = {
  tabId: string;
  points: SyncableExcalidrawElement[];
};

export type MqttWhiteboardTabPayload = {
  tabId: string;
  title?: string;
  type: string;
  url?: string;
};

export type MqttWhiteboardPayload = {
  tabId: string;
  title: string;
  page: number;
  type: string;
};

export interface MousePointerUpdate {
  pointer: {
    x: number;
    y: number;
    tool: "pointer" | "laser";
  };
  button: "down" | "up";
  selectedElementsId: { [key: string]: boolean } | null;
  userId: string;
  username: string;
  pointersMap?: Gesture["pointers"];
}

export interface PlayerStateUpdate {
  action: string;
  seekTo?: number;
  tabId: string;
}


export interface EditorStateUpdate {
  action: string;
  data: any;
  tabId: string;
}