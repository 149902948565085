import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Mic from "@mui/icons-material/Mic";
import Videocam from "@mui/icons-material/Videocam";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockIcon from "@mui/icons-material/Block";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChatIcon from "@mui/icons-material/Chat";
import PresentToAllIcon from "@mui/icons-material/PresentToAll";
import EditOffIcon from "@mui/icons-material/EditOff";
import {
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Collapse,
  List,
  ListItemIcon,
  ListItem,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { Participant, ParticipantControls } from "../../../types/livekit";
import { useAtom, useAtomValue } from "jotai";
import Close from "@mui/icons-material/Close";
import VideocamOff from "@mui/icons-material/VideocamOff";
import MicOff from "@mui/icons-material/MicOff";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import PresentToAll from "@mui/icons-material/PresentToAll";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { updateParticipant } from "../../../store/participantList";
import { syncData } from "../../../utils/roomClient";
import { currentSessionAtom } from "../../../store/session";
import { getMediaServerConn } from "../../../utils/livekitClient";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    // Generate values but restrict to dark shades
    let value = (hash >> (i * 8)) & 0xff;

    // Ensure the value is in the range [0, 128] for darker colors
    value = Math.floor(value / 2); // Divide by 2 to reduce brightness

    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  if (!name.includes(" ")) {
    name += " " + name.charAt(1);
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

interface ParticipantListItemProps {
  participant: Participant;
}

export const ParticipantListItem = ({
  participant,
}: ParticipantListItemProps) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openOptions = Boolean(anchorEl);

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  const conn = getMediaServerConn();

  const currentSession = useAtomValue(currentSessionAtom);

  const onCllick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            alt={participant.name}
            src={participant.image}
            {...stringAvatar(participant.name)}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            currentSession?.me.userId == participant.userId
              ? participant.name + " (me)"
              : participant.name
          }
        />

        {currentSession?.me.userId != participant.userId && (
          <>
            <>
              <EditIcon
                color={participant.controls?.write ? "inherit" : "disabled"}
                style={{ marginRight: "4px", fontSize: "14px" }}
              />
              {participant.isAudioMuted ? (
                <MicOff
                  color={participant.controls?.audio ? "inherit" : "disabled"}
                  style={{ marginRight: "4px", fontSize: "14px" }}
                />
              ) : (
                <Mic
                  color={participant.controls?.audio ? "inherit" : "disabled"}
                  style={{ marginRight: "4px", fontSize: "14px" }}
                />
              )}

              {participant.isVideoMuted ? (
                <VideocamOff
                  color={participant.controls?.video ? "inherit" : "disabled"}
                  style={{ marginRight: "4px", fontSize: "14px" }}
                />
              ) : (
                <Videocam
                  color={participant.controls?.video ? "inherit" : "disabled"}
                  style={{ marginRight: "4px", fontSize: "14px" }}
                />
              )}
            </>

            <IconButton aria-label="more" onClick={onCllick}>
              <MoreVertIcon />
            </IconButton>

            <Menu open={openOptions} anchorEl={anchorEl} onClose={handleClose}>
              <MenuItem
                onClick={() => {
                  const controls: ParticipantControls = {
                    write: !participant.controls?.write,
                    audio: participant.controls?.audio ?? false,
                    video: participant.controls?.video ?? false,
                    chat: participant.controls?.chat ?? false,
                    screen: participant.controls?.screen ?? false,
                  };

                  updateParticipantControls(
                    currentSession?.roomId!,
                    participant.userId,
                    controls,
                  );
                  handleClose();

                }}
              >
                {participant.controls?.write ? (
                  <>
                    <EditOffIcon
                      style={{ marginRight: "10px" }}
                      fontSize="small"
                    />
                    Lock whiteboard
                  </>
                ) : (
                  <>
                    <EditIcon
                      style={{ marginRight: "10px" }}
                      fontSize="small"
                    />
                    Unlock whiteboard
                  </>
                )}
              </MenuItem>

              <MenuItem onClick={() => {
                  const controls: ParticipantControls = {
                    write: participant.controls?.write ?? false,
                    audio: !participant.controls?.audio,
                    video: participant.controls?.video ?? false,
                    chat: participant.controls?.chat ?? false,
                    screen: participant.controls?.screen ?? false,
                  };

                  updateParticipantControls(
                    currentSession?.roomId!,
                    participant.userId,
                    controls,
                  );
                  handleClose();

                }}>
                {participant.controls?.audio ? (
                  <>
                    <MicOff style={{ marginRight: "10px" }} fontSize="small" />
                    Lock microphone
                  </>
                ) : (
                  <>
                    <Mic style={{ marginRight: "10px" }} fontSize="small" />
                    Unlock microphone
                  </>
                )}
              </MenuItem>

              <MenuItem onClick={() => {
                  const controls: ParticipantControls = {
                    write: participant.controls?.write ?? false,
                    audio: participant.controls?.audio ?? false,
                    video: !participant.controls?.video ,
                    chat: participant.controls?.chat ?? false,
                    screen: participant.controls?.screen ?? false,
                  };

                  // if(!controls.video) {
                  //   conn.removeWebcam(participant.userId);
                  // }

                  updateParticipantControls(
                    currentSession?.roomId!,
                    participant.userId,
                    controls,
                  );
                  handleClose();

                }}>
                {participant.controls?.video ? (
                  <>
                    <VideocamOff
                      style={{ marginRight: "10px" }}
                      fontSize="small"
                    />
                    Lock webcam
                  </>
                ) : (
                  <>
                    <Videocam
                      style={{ marginRight: "10px" }}
                      fontSize="small"
                    />
                    Unlock webcam
                  </>
                )}
              </MenuItem>

              <MenuItem onClick={() => {
                  const controls: ParticipantControls = {
                    write: participant.controls?.write ?? false,
                    audio: participant.controls?.audio ?? false,
                    video: participant.controls?.video ?? false,
                    chat: !participant.controls?.chat,
                    screen: participant.controls?.screen ?? false,
                  };

                  updateParticipantControls(
                    currentSession?.roomId!,
                    participant.userId,
                    controls,
                  );
                  handleClose();

                }}>
                {participant.controls?.chat ? (
                  <>
                    <SpeakerNotesOffIcon
                      style={{ marginRight: "10px" }}
                      fontSize="small"
                    />
                    Lock chat
                  </>
                ) : (
                  <>
                    <ChatIcon
                      style={{ marginRight: "10px" }}
                      fontSize="small"
                    />
                    Unlock chat
                  </>
                )}
              </MenuItem>

              <MenuItem onClick={() => {
                  const controls: ParticipantControls = {
                    write: participant.controls?.write ?? false,
                    audio: participant.controls?.audio ?? false,
                    video: participant.controls?.video ?? false,
                    chat: participant.controls?.chat ?? false,
                    screen: !participant.controls?.screen,
                  };

                  updateParticipantControls(
                    currentSession?.roomId!,
                    participant.userId,
                    controls,
                  );

                  handleClose();
                }}>
                {participant.controls?.screen ? (
                  <>
                    <CancelPresentationIcon
                      style={{ marginRight: "10px" }}
                      fontSize="small"
                    />
                    Lock screen sharing
                  </>
                ) : (
                  <>
                    <PresentToAll
                      style={{ marginRight: "10px" }}
                      fontSize="small"
                    />
                    Unlock screen sharing
                  </>
                )}
              </MenuItem>

              <MenuItem onClick={handleClose}>
                {participant.isPresenter ? (
                  <>
                    <VisibilityOffIcon
                      style={{ marginRight: "10px" }}
                      fontSize="small"
                    />
                    Downgrade from presenter
                  </>
                ) : (
                  <>
                    <VisibilityIcon
                      style={{ marginRight: "10px" }}
                      fontSize="small"
                    />
                    Make presenter
                  </>
                )}
              </MenuItem>

              {!participant.isAudioMuted && (
                <MenuItem onClick={()=>syncData.PrivatePublish(currentSession?.roomId!, currentSession?.me.userId!,participant.userId,"muteAudio")}>
                  <MicOff style={{ marginRight: "10px" }} fontSize="small" />
                  Mute audio
                </MenuItem>
              )}

              {!participant.isVideoMuted && (
                <MenuItem onClick={()=>syncData.PrivatePublish(currentSession?.roomId!, currentSession?.me.userId!,participant.userId,"muteVideo")}>
                  <VideocamOff
                    style={{ marginRight: "10px" }}
                    fontSize="small"
                  />
                  Stop video
                </MenuItem>
              )}

              <MenuItem onClick={handleClose}>
                <BlockIcon style={{ marginRight: "10px" }} fontSize="small" />
                Remove
              </MenuItem>
            </Menu>
          </>
        )}

        {/* {open ? <ExpandLess /> : <ExpandMore />} */}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Write Access" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <Mic />
            </ListItemIcon>
            <ListItemText primary="Audio Access" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <Videocam />
            </ListItemIcon>
            <ListItemText primary="Video Access" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText primary="Make Presenter" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <VolumeOffIcon />
            </ListItemIcon>
            <ListItemText primary="Mute" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <BlockIcon />
            </ListItemIcon>
            <ListItemText primary="Remove" />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};

const updateParticipantControls = (
  roomId: string,
  userId: string,
  controls: ParticipantControls,
) => {
  const p = updateParticipant(userId, {
    controls: {
      write: controls?.write ?? false,
      audio: controls?.audio ?? false,
      video: controls?.video ?? false,
      chat: controls?.chat ?? false,
      screen: controls?.screen ?? false,
    },
  });
  if (p) {
    syncData.UserControlStatus(roomId,"", p);
  }
};
