import { useAtom, useAtomValue, useSetAtom } from "jotai"; // useAtom to read and update the visibility atom

import "./AppsList.scss"; // Ensure this file contains the necessary styles
import React, { useState } from "react";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// import { appsListItem } from "./_components/appsListItem";
import Close from "@mui/icons-material/Close";
import { currentSessionAtom, isAppsVisible, isExternalPageModalOpen, isExternalVideoModalOpen, isFileModalOpen } from "../../store/session";
import { FileOpenSharp, VideoFileSharp } from "@mui/icons-material";
import WebIcon from '@mui/icons-material/Web';
import { fetchConvertedFiles } from "../../api";
import { randomId } from "../../../packages/excalidraw/random";
import { syncData } from "../../utils/roomClient";
import { prepareForExcalidraw } from "../whiteboard/sync";
import { Tab } from "../../types/tabs";
import { appJotaiStore } from "../../app-jotai";
export const AppsList = () => {
  const [isVisible, setIsVisible] = useAtom(isAppsVisible); // Read and write visibility atom

  const openExternalVideoLink = useSetAtom(isExternalVideoModalOpen);

  const openExternalPageLink = useSetAtom(isExternalPageModalOpen);

  const currentSession = useAtomValue(currentSessionAtom);

  return (
    <>
      <div className={`apps-container ${isVisible ? "visible" : ""}`}>
        <div className="apps-header">
          <h2>Apps</h2>
          <IconButton onClick={() => setIsVisible(false)}>
            <Close />
          </IconButton>
        </div>

        <List>
          <ListItem>
            <ListItemButton
              onClick={() => {
                setIsVisible(false);
                openExternalVideoLink(true);
              }}
            >
              <ListItemIcon>
                <VideoFileSharp />
              </ListItemIcon>
              <ListItemText>External Media Player</ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={() => {
                setIsVisible(false);
                // openExternalVideoLink(true);
                appJotaiStore.set(isFileModalOpen,true);
               
              }}
            >
              <ListItemIcon>
                <FileOpenSharp />
              </ListItemIcon>
              <ListItemText>Files</ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={() => {
                setIsVisible(false);
                openExternalPageLink(true);
              }}
            >
              <ListItemIcon>
                <WebIcon />
              </ListItemIcon>
              <ListItemText>Webpage</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </div>

      {isVisible && (
        <div className="overlay" onClick={() => setIsVisible(false)}></div>
      )}
    </>
  );
};
