export const ROOM_API1 = "http://localhost:8000"
export const ROOM_API = "https://api1.simpech.com/room"

export const CONTENT_API = "https://api1.simpech.com/content"
export const CONTENT_API1 = "http://localhost:6020"


export const CLASS_API1 = "http://localhost:8010"
export const CLASS_API = "https://api1.simpech.com/class"

export const ACCOUNT_API1 = "http://localhost:8080"
export const ACCOUNT_API = "https://api1.simpech.com/account"

export const DOMAIN1 = ".localhost"
export const DOMAIN  = ".simpech.com"
