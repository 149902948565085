import { enqueueSnackbar } from "notistack";
import { appJotaiStore } from "../app-jotai";
import {
  addParticipant,
  removeParticipant,
  updateParticipant,
} from "../store/participantList";
import {
  currentSessionAtom,
  editorStateAtom,
  externalMediaPlayerAtom,
  LayoutSettings,
  sessionLayoutAtom,
} from "../store/session";
import {
  addTab,
  currentTabAtom,
  getTab,
  removeTab,
  setCurrentTab,
} from "../store/tabList";
import { Participant } from "../types/livekit";
import {
  EditorStateUpdate,
  MqttMessage,
  MqttMessageType,
  MqttWhiteboardTabPayload,
  PlayerStateUpdate,
} from "../types/whiteboard";
import { getMediaServerConn } from "./livekitClient";
import MqttService, { getMqttConn } from "./mqttClient";
import {
  addMessage,
  deleteMessage,
  Message,
  updateMessage,
} from "../store/chat";
import { Delete } from "@mui/icons-material";
import { fetchConvertedFiles } from "../api";
import { Tab } from "../types/tabs";

export const syncData = {
  switchTab: (roomId: string, userId: string, tab: Tab) => {
    const finalMsg: MqttWhiteboardTabPayload = {
      tabId: tab.tabId,
      type: tab.type,
    };

    const msgReq: MqttMessage = {
      data: JSON.stringify(finalMsg),
      userId: userId,
      roomId: roomId,
      type: MqttMessageType.SWITCH_TAB,
      time: Date.now(),
    };
    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msgReq),
    );
  },

  deleteTab: (roomId: string, userId: string, tab: Tab) => {
    const finalMsg: MqttWhiteboardTabPayload = {
      tabId: tab.tabId,
      type: tab.type,
    };

    const msgReq: MqttMessage = {
      data: JSON.stringify(finalMsg),
      userId: userId!,
      roomId: roomId!,
      type: MqttMessageType.DELETE_TAB,
      time: Date.now(),
    };

    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msgReq),
    );
  },

  createTab: (roomId: string, userId: string, tab: Tab) => {
    const finalMsg: MqttWhiteboardTabPayload = {
      tabId: tab.tabId,
      title: tab.title,
      type: tab.type,
      url: tab.url,
    };

    const msgReq: MqttMessage = {
      data: JSON.stringify(finalMsg),
      userId: userId!,
      roomId: roomId!,
      type: MqttMessageType.CREATE_TAB,
      time: Date.now(),
    };

    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msgReq),
    );
  },

  initParticipant: (roomId: string, participant: Participant) => {
    const msg: MqttMessage = {
      type: MqttMessageType.USER_ONLINE,
      userId: participant.userId,
      roomId,
      time: Date.now(),
      data: JSON.stringify(participant),
    };

    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msg),
    );
  },

  PrivatePublish: (
    roomId: string,
    userId: string,
    to: string,
    data: string,
  ) => {
    const msg: MqttMessage = {
      type: MqttMessageType.PRIVATE,
      userId: userId,
      roomId,
      time: Date.now(),
      data: data,
      to: to,
    };

    console.log("published user mute status :: ", msg);
    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msg),
    );
  },

  UserMuteStatus: (
    roomId: string,
    userId: string,
    participant: Participant,
  ) => {
    const msg: MqttMessage = {
      type: MqttMessageType.USER_MUTE_STATUS,
      userId: userId,
      roomId,
      time: Date.now(),
      data: JSON.stringify(participant),
      // to:to,
    };

    console.log("published user mute status :: ", msg);
    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msg),
    );
  },
  UserTalked: (roomId: string, userId: string, participant: Participant) => {
    const msg: MqttMessage = {
      type: MqttMessageType.USER_TALKED,
      userId: userId,
      roomId,
      time: Date.now(),
      data: JSON.stringify(participant),
    };

    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msg),
    );
  },

  UserControlStatus: (
    roomId: string,
    userId: string,
    participant: Participant,
  ) => {
    const msg: MqttMessage = {
      type: MqttMessageType.USER_CONTROL_STATUS,
      userId: userId ?? appJotaiStore.get(currentSessionAtom)?.me.userId,
      roomId,
      time: Date.now(),
      data: JSON.stringify(participant),
    };

    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msg),
    );
  },
  PlayerState: (
    roomId: string,
    userId: string,
    tabId: string,
    action: string,
    seekTo?: number,
  ) => {
    const finalMsg: PlayerStateUpdate = {
      tabId: tabId,
      seekTo: seekTo,
      action: action,
    };

    const msg: MqttMessage = {
      type: MqttMessageType.PLAYER_STATE,
      userId: userId ?? appJotaiStore.get(currentSessionAtom)?.me.userId,
      roomId,
      time: Date.now(),
      data: JSON.stringify(finalMsg),
    };

    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msg),
    );
  },

  EditorState: (
    roomId: string,
    userId: string,
    tabId: string,
    action: string,
    data: any,
  ) => {
    const finalMsg: EditorStateUpdate = {
      tabId: tabId,
      data: data,
      action: action,
      // userId:userId
    };

    const msg: MqttMessage = {
      type: MqttMessageType.EDITOR_STATE,
      userId: userId ?? appJotaiStore.get(currentSessionAtom)?.me.userId,
      roomId,
      time: Date.now(),
      data: JSON.stringify(finalMsg),
    };

    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msg),
    );
  },

  LayoutChange: (roomId: string, userId: string, layout: LayoutSettings) => {
    const msg: MqttMessage = {
      type: MqttMessageType.LAYOUT_CHANGE,
      userId: userId ?? appJotaiStore.get(currentSessionAtom)?.me.userId,
      roomId,
      time: Date.now(),
      data: JSON.stringify(layout),
    };

    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msg),
    );
  },

  SendNewMessage: (roomId: string, userId: string, message: Message) => {
    const msg: MqttMessage = {
      type: MqttMessageType.NEW_MESSAGE,
      userId: userId ?? appJotaiStore.get(currentSessionAtom)?.me.userId,
      roomId,
      time: Date.now(),
      data: JSON.stringify(message),
    };

    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msg),
    );
  },

  UpdateMessage: (
    roomId: string,
    userId: string,
    messageId: string,
    changes: Partial<Message>,
  ) => {
    const msg: MqttMessage = {
      type: MqttMessageType.UPDATE_MESSAGE,
      userId: userId ?? appJotaiStore.get(currentSessionAtom)?.me.userId,
      roomId,
      time: Date.now(),
      data: JSON.stringify({ messageId, changes }),
    };

    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msg),
    );
  },

  DeleteMessage: (roomId: string, userId: string, messageId: string) => {
    const msg: MqttMessage = {
      type: MqttMessageType.DELETE_MESSAGE,
      userId: userId ?? appJotaiStore.get(currentSessionAtom)?.me.userId,
      roomId,
      time: Date.now(),
      data: messageId,
    };

    getMqttConn().publish(
      MqttService.getClassroomTopic(roomId),
      JSON.stringify(msg),
    );
  },
};

export const syncCallback = (payload: Buffer) => {
  // console.log("sync callback....", payload.toString());
  const inputString = payload.toString();
  const [timestamp, mese] = inputString!.match(/^(\d+)\/(.+)$/)!.slice(1, 3);

  // const mese = payload.toString().split("/",2)[1];

  // console.log("sync callback....", mese);

  const message = JSON.parse(mese);
  const currentSession = appJotaiStore.get(currentSessionAtom);

  if (message.type === MqttMessageType.CREATE_TAB) {
    const tabData = JSON.parse(message.data);
    const tabmeta: Tab = {
      tabId: tabData.tabId,
      type: tabData.type,
      title: tabData.title,
      url:tabData.url
    };

    addTab(tabmeta);
   

    // if (tabmeta.type == "file") {
    //   fetchConvertedFiles(tabData.url).then(async (res) => {
    //     const jsonUrl = res.rootUrl + res.url;
    //     console.log("json url: ", jsonUrl);

    //     const jsonRes = await fetch(jsonUrl);

    //     const jsonData = await jsonRes.json();

    //     let pages:Page[] = [];

    //     for(let i=0;i<jsonData.files.length;i++){
    //       const page: Page = {
    //         points: [],
    //         index: i,fsce
    //         url: jsonData.files[i]
    //       };
    //       pages.push(page);
    //     }

    //     const tab: Tab = {
    //       type: tabmeta.type,
    //       pages: pages,
    //       tabId: tabmeta.tabId,
    //       url: tabData.url,
    //     };
    //     Tabs.set(tab.tabId, tab);
    //   });
    // } else {
      // const tab: Tab = {
      //   type: tabmeta.type,
      //   pages: [page],
      //   tabId: tabmeta.tabId,
      //   url: tabData.url,
      // };
      // Tabs.set(tab.tabId, tab);
    // }

    setCurrentTab(tabmeta);
  }

  if (message.type === MqttMessageType.SWITCH_TAB) {
    const tabData = JSON.parse(message.data);
    const currentTab = getTab(tabData.tabId);

    if (currentTab) {
      appJotaiStore.set(currentTabAtom, currentTab);
    }
  }

  if (message.type === MqttMessageType.DELETE_TAB) {
    const tabData = JSON.parse(message.data);
    console.log("delete ho ne aayaa....", tabData);

    removeTab(tabData.tabId);
    if (tabData.type == "screen") {
      const conn = getMediaServerConn();
      console.log("remove krk rhe track...");
      conn.removeScreenTrack(tabData.tabId);
    }
  }

  if (message.type === MqttMessageType.USER_ONLINE) {
    console.log("partipant is online ka message aayaa...aa");
    const p = JSON.parse(message.data) as Participant;
    if (p.userId == currentSession?.me.userId) {
      p.isLocal = true;
    }
    addParticipant(p);
    enqueueSnackbar(p.name + " is online", { variant: "info" });
  }

  if (message.type === MqttMessageType.USER_OFFLINE) {
    const p = JSON.parse(message.data) as Participant;
    removeParticipant(p.userId);
    enqueueSnackbar(p.userId + " is offline.", { variant: "info" });
    // const conn = getMediaServerConn();

    // conn.removeAudioSubscriber(p.userId);
    // conn.removeVideoSubscriber(p.userId);
  }

  if (message.type === MqttMessageType.USER_CONTROL_STATUS) {
    const p = JSON.parse(message.data) as Participant;
    if (message.userId == currentSession?.me.userId) {
      return;
    }
    updateParticipant(p.userId, {
      controls: p.controls,
    });
  }

  if (message.type === MqttMessageType.USER_MUTE_STATUS) {
    const p = JSON.parse(message.data) as Participant;
    if (message.userId == currentSession?.me.userId) {
      return;
    }

    updateParticipant(p.userId, {
      isAudioMuted: p.isAudioMuted,
      isVideoMuted: p.isVideoMuted,
      audioEnabled: p.audioEnabled,
      videoEnabled: p.videoEnabled,
    });
  }

  if (message.type === MqttMessageType.PRIVATE) {
    if (message.to != currentSession?.me.userId) {
      return;
    }

    const conn = getMediaServerConn();

    if (message.data == "muteAudio") {
      conn.muteAudio();
    } else if (message.data == "muteVideo") {
      conn.muteVideo();
    }
  }

  if (message.type === MqttMessageType.PLAYER_STATE) {
    if (message.userId == currentSession?.me.userId) {
      return;
    }

    const data = JSON.parse(message.data);

    // console.log("set kr rha jotai me....",data )
    appJotaiStore.set(externalMediaPlayerAtom, {
      action: data.action,
      seekTo: data.seekTo ?? -1,
    });
  }

  if (message.type === MqttMessageType.EDITOR_STATE) {
    if (message.userId == currentSession?.me.userId) {
      return;
    }

    const data = JSON.parse(message.data);

    console.log("set kr rha jotai me....", data);
    appJotaiStore.set(editorStateAtom, {
      action: data.action,
      data: data.data,
      userId: message.userId,
    });
  }

  if (message.type === MqttMessageType.LAYOUT_CHANGE) {
    if (message.userId == currentSession?.me.userId) {
      return;
    }

    const layout = JSON.parse(message.data) as LayoutSettings;

    appJotaiStore.set(sessionLayoutAtom, layout);
  }

  if (message.type === MqttMessageType.NEW_MESSAGE) {
    if (message.userId == currentSession?.me.userId) {
      return;
    }

    const data = JSON.parse(message.data) as Message;
    addMessage(data);
  }

  if (message.type === MqttMessageType.UPDATE_MESSAGE) {
    if (message.userId == currentSession?.me.userId) {
      return;
    }

    const data = JSON.parse(message.data) as {
      messageId: string;
      changes: Partial<Message>;
    };
    updateMessage(data.messageId, data.changes);
  }

  if (message.type === MqttMessageType.DELETE_MESSAGE) {
    if (message.userId == currentSession?.me.userId) {
      return;
    }

    const messageId = message.data as string;
    deleteMessage(messageId);
  }
};
