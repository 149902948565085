import { atom, useAtom } from "jotai";
import { appJotaiStore } from "../app-jotai";


export type Message = {  
    messageId: string;
    message: string;
    type:string;
    sender: string;
    timestamp: number;
 }


export const chatListAtom = atom<Message[]>([]);

export const addMessage = (message: Message) => {
    appJotaiStore.set(chatListAtom, (prev) => [...prev, message]);
};

export const deleteMessage = (messageId: string) => {
    appJotaiStore.set(chatListAtom, (prev) => prev.filter((message) => message.messageId !== messageId));
};

export const updateMessage = (messageId: string, changes: Partial<Message>) => {
    appJotaiStore.set(chatListAtom, (prev) => {
        return prev.map((message) => {
            if (message.messageId === messageId) {
                return { ...message, ...changes };
            }
            return message;
        });
    });
};

export const getMessages = () => {
    return appJotaiStore.get(chatListAtom);
};