import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import "./index.scss"
import { syncData } from "../../utils/roomClient";
import { useAtomValue } from "jotai";
import { currentSessionAtom } from "../../store/session";
interface ExternalVideoPlayerProps {
  src: string;
  action: string;
  seekTo: number;
  tabId:string;
}

export const ExternalVideoPlayer = ({ src,action,seekTo,tabId }: ExternalVideoPlayerProps) => {
  const [paused, setPaused] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const player = useRef<ReactPlayer>();
  const currentSession = useAtomValue(currentSessionAtom);
  


  useEffect(()=>{
    if(action === 'play') {
        setIsPlaying(true);
    }else if(action === 'pause') {
        setIsPlaying(false);
    }
  },[action])

  useEffect(()=>{
    if(!isReady) {
        return
    }

    if(seekTo >1 && player) {
        player.current?.seekTo(seekTo);
    }

  },[seekTo, player, isReady]);


  useEffect(()=>{
    if(!isReady || !player) {
        return;
    }


    let action = "play";
    if(paused) {
        action = "pause";
    }

    console.log("publish kr rha....")
    syncData.PlayerState(currentSession?.roomId!, currentSession?.me.userId!, tabId,action,player.current?.getCurrentTime())

  },[isReady, paused, player])


  const onReady = () => {
    console.log("on ready..")
    setIsReady(true);
  };

  const onPause = () => {
    console.log("on pause..")

    setPaused(true);
  };

  const onPlay = () => {
    console.log("on play..")

    setPaused(false);
  };

  const ref = (_player) => {
    player.current = _player;
  };

  return <div className="player-wrapper">
    <ReactPlayer
    className='react-player'
    ref={ref}
    url={src}
    controls={true}
    playing={isPlaying}
    onReady={onReady}
    onPause={onPause}
    onPlay={onPlay}
    width="100%"
    height="100%"
    />
  </div>;
};
