import React, { useEffect, useRef, useState } from "react";
import ExcalidrawApp from "./components/whiteboard";
import { useLocation } from "react-router-dom";
import { atom, useAtom, useAtomValue } from "jotai";
import { appJotaiStore } from "./app-jotai";
import { fetchLivekitToken, fetchParticipants, fetchSessionState } from "./api";
import MqttService, {
  createMqttConnection,
  getMqttConn,
} from "./utils/mqttClient";
import { Allotment, AllotmentHandle } from "allotment";
import AudioVideoLayout from "./components/audio-video";
import "./App.scss";
import "allotment/dist/style.css";
import { createLivekitConnection } from "./utils/livekitClient";
import { participantHandler } from "./utils/participantHandler";
import { ParticipantRole, type Participant } from "./types/livekit";
import { ConnectionQuality } from "livekit-client";
import type { MqttMessage } from "./types/whiteboard";
import { MqttMessageType } from "./types/whiteboard";
import ControlsLayout from "./components/bottom-bar/controls-layout";
import { syncCallback, syncData } from "./utils/roomClient";
import {
  addParticipant,
  addParticipants,
  participantListAtom,
  participantLoadingAtom,
} from "./store/participantList";
import { ParticipantComponent } from "./components/participants";
import {
  currentSessionAtom,
  LayoutSettings,
  Session,
  sessionLayoutAtom,
} from "./store/session";
import DeviceTestPage from "./components/device-test";
import { SettingsModal } from "./components/settings";
import { AppsList } from "./components/apps";
import { ExternalVideoLinkModal } from "./components/external-media/_components/modal";
import { ExternalPageLinkModal } from "./components/webpage/_components/modal";
import { getWebcamResolution } from "./helpers/utils";
import { ChatComponent } from "./components/chats";
import { ExitComponent } from "./components/exit";
import FileModal from "./components/files";
import { Button, Dialog, DialogContent, Modal, TextField } from "@mui/material";
import { startClass } from "./api/room";
import { useHandleAppTheme } from "./useHandleAppTheme";

const App = () => {
  const { editorTheme } = useHandleAppTheme();

  const [session, setSession] = useAtom(currentSessionAtom);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const mqttInitialized = useRef<boolean>(false);
  const [layout, setLayout] = useAtom(sessionLayoutAtom);
  const [isStartModalVisible, setIsStartModalVisible] = useState(false); // Start modal visibility
  const ref = React.useRef<AllotmentHandle | null>(null);
  const [topic, setTopic] = useState(""); // State for the topic

  useEffect(() => {
    console.log("layout state changed...", layout);
    if (ref.current) {
      console.log("reff", ref.current);
      ref.current.resize([layout.whiteboard, layout.video]);
      // ref.current.reset();
    }
  }, [layout]);

  // Function to handle the start button click
  const handleStart = async () => {
    if (session?.roomId && topic) {
      const res = await startClass(session.roomId, topic);
      console.log("res: ", res);
      setSession({
        ...session,
        subRoomId: res.subClassId,
      });
    }
  };

  const handleLater = () => {
    setIsStartModalVisible(false);
  };

  useEffect(() => {
    if (session) {
      setIsStartModalVisible(!session.subRoomId);
    }

    if (session && !mqttInitialized.current) {
      setLoading(true);
      fetchUserAndRoomDetails(session)
        .then(() => {
          mqttInitialized.current = true; // Prevent re-initialization
          setLoading(false);
        })
        .catch((erro: any) => {
          setLoading(false);
          setErrorMessage(erro.message);
        });
    }
  }, [session]);

  if (!session) {
    return <DeviceTestPage />;
  }

  if (errorMessage) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <p className="text-xl text-red-500">{errorMessage}</p>
        </div>
      </div>
    );
  }

  if (loading) {
    return <div>Loading, please wait...</div>;
  }

  return (
    <div
      className={`excalidraw main-container ${
        editorTheme == "dark" ? "theme--dark" : "theme--light"
      }`}
    >
      <Dialog open={isStartModalVisible}>
        <DialogContent
          className={`excalidraw ${
            editorTheme == "dark" ? "theme--dark" : "theme--light"
          }`}
        >
          <div className="flex flex-col items-center justify-center p-8">
            <h1 className="text-2xl font-bold mb-4">
              Welcome to the Classroom
            </h1>
            <p className="text-lg text-center mb-4">
              Before you start, make sure your camera and microphone are working
              properly.
            </p>

            <TextField
              variant="outlined"
              fullWidth
              margin="normal"
              value={topic}
              size="small"
              onChange={(e) => setTopic(e.target.value)}
              placeholder="Enter the topic for today"
              style={{
                background: "var(--input-bg-color)",
                borderColor: "var(--input-border-color)",
              }}
            />

            <Button
              variant="contained"
              onClick={handleStart}
              style={{
                marginTop: 4,
                marginRight: 10,
                color: "var(--color-surface-mid)",
                backgroundColor: "var(--color-on-surface)",
              }}
            >
              Start
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={handleLater}
              style={{
                marginTop: 4,
                color: "var(--primary-color)",
                borderColor: "var(--dialog-border-color)",
                backgroundColor: "var(--island-bg-color)",
              }}
            >
              Later
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <div className="main-content">
        <Allotment
          ref={ref}
          defaultSizes={[layout.whiteboard, layout.video]}
          separator={true}
          onDragEnd={(sizes) => {
            console.log("on drag end: ", sizes);
            const newLayout: LayoutSettings = {
              whiteboard: sizes[0],
              video: sizes[1],
              chat: 0,
            };
            setLayout(newLayout);

            syncData.LayoutChange(session.roomId, session.me.userId, newLayout);
          }}
        >
          <Allotment.Pane snap={true} visible={layout.whiteboard > 0}>
            <ExcalidrawApp />
          </Allotment.Pane>
          <Allotment.Pane snap={true} visible={layout.video > 0}>
            <AudioVideoLayout />
          </Allotment.Pane>
        </Allotment>
      </div>

      <ControlsLayout />
      <ParticipantComponent />
      <ChatComponent />
      <AppsList />
      <SettingsModal />
      <ExternalVideoLinkModal />
      <ExternalPageLinkModal />
      <ExitComponent />
      <FileModal />
    </div>
  );
};

export default App;

const fetchUserAndRoomDetails = async (session: Session) => {
  try {
    // connecting to mqtt;
    const mqttConnection = createMqttConnection(
      `${session.roomId}+${session.me.userId}`,
      session.mqttUrl,
    );

    await mqttConnection.connect(
      {},
      () => {
        console.log("Connected to MQTT, executing onConnect tasks...");

        setTimeout(() => {
          syncData.initParticipant(session.roomId, session.me);
        }, 1000);

        fetchParticipants(session.roomId).then((participants) => {
          console.log("got participants....", participants);
          if (participants) {
            addParticipants(participants);
          }
        });
      },
      (err) => {
        console.log("error while mqtt connection.");
      },
    );

    getMqttConn().subscribe(MqttService.getClassroomTopic(session.roomId));
    getMqttConn().subscribe(MqttService.getWhiteboardTopic(session.roomId));

    getMqttConn().setMessageHandler((topic, payload) => {
      if (topic == MqttService.getClassroomTopic(session.roomId)) {
        syncCallback(payload);
      }
    });

    const livekitcon = createLivekitConnection(
      session.roomId,
      session.me.userId,
      session.livekitUrl,
      session.token,
    );
    await livekitcon.connect();

    await livekitcon.publishTrack(
      session?.me?.audioEnabled && !session.me.isAudioMuted,
      session?.me?.videoEnabled && !session.me.isVideoMuted,
    );
  } catch (error) {}
};
