import IconButton from "@mui/material/IconButton";

import React from "react";

import { useAtom, useSetAtom } from "jotai";
import { isParticipantListVisibleAtom } from "../../../store/participantList";
import { People } from "@mui/icons-material";

function PeopleIcon() {

  const [peopleVisible,togglePeopleList] = useAtom(isParticipantListVisibleAtom); // Set the atom to toggle visibility

  return (
    <IconButton
      style={{
        // border: "1px solid #ccc",
        // background: "#ccc",
        marginLeft: "5px",
      }}
      size="large"
      onClick={() => togglePeopleList(prev => !prev)} // Toggle the visibility 
    >
      <People  />
      
    </IconButton>
  );
}

export default PeopleIcon;
