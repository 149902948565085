import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useAtom, useAtomValue } from "jotai";
import React from "react";
import { currentSessionAtom, isExitModalOpen } from "../../store/session";
import { endClass } from "../../api/room";
import { useHandleAppTheme } from "../../useHandleAppTheme";

export const ExitComponent = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(isExitModalOpen);
  const { editorTheme } = useHandleAppTheme();

  const session = useAtomValue(currentSessionAtom);
  const onClose = () => {
    setIsModalOpen(false);
  };

  const onLeave = () => {
    // Leave the classroom
    setIsModalOpen(false);
    window.location.href = "https://simpech.com";

  };

  const onExit =async () => {
    // Exit the classroom
    if (!session) return;
    // setIsModalOpen(false);
    await endClass(session.roomId);
    window.location.href = "https://simpech.com";
  };

  return (
     
<Dialog open={isModalOpen} onClose={onClose}>
        <DialogContent
          className={`excalidraw ${
            editorTheme == "dark" ? "theme--dark" : "theme--light"
          }`}
        >
          <div className="flex flex-col items-center justify-center p-8">
            <h1 className="text-2xl font-bold mb-4">
              Exit classroom
            </h1>
            <p className="text-lg text-center mb-4">
            Are you sure you want to leave the classroom? You can always join back later.
            </p>

           
            <Button
              variant="outlined"
              color="primary"
              onClick={onLeave}
              style={{
                marginTop: 4,
                marginRight: 10,

                color: "var(--primary-color)",
                borderColor: "var(--dialog-border-color)",
                backgroundColor: "var(--island-bg-color)",
              }}
            >
              Leave
            </Button>

            <Button
              variant="contained"
              onClick={onExit}
              style={{
                marginTop: 4,
                color: "var(--color-surface-mid)",
                backgroundColor: "var(--color-on-surface)",
              }}
            >
              End for all
            </Button>

          </div>
        </DialogContent>
      </Dialog>

  );
};
