import type {
  LocalParticipant,
  Participant as LivekitParticipant,
  RemoteParticipant,
} from "livekit-client";
import { Track } from "livekit-client";
import React, { useEffect, useRef, useState } from "react";
import {
  CurrentConnectionEvents,
  getMediaServerConn,
} from "../../utils/livekitClient";
import { VideoBox } from "./_components/video/VideoBox";
import { GridLayout } from "./grid-layout";
// import AudioElements from "./_components/audio/index";
const AudioVideoLayout = () => {
  const parentR = useRef<HTMLDivElement>(null);

  const [subscribers, setSubscribers] =
    useState<
      Map<string, LivekitParticipant | LocalParticipant | RemoteParticipant>
    >();
  const currentConnection = getMediaServerConn();
  // console.log("current connection bnaa hai ya ni.....", currentConnection);

  useEffect(() => {
    // Listen to VideoSubscribers event to update the video participants
    currentConnection.on(
      CurrentConnectionEvents.Subscribers,
      (subcribers)=>{
        setSubscribers(subcribers);
      }
    );

    // Fetch existing subscribers on mount
    setSubscribers(new Map(currentConnection.getSubscribers()));

    // Fetch existing subscribers on mount
    // setVideoSubscribers(new Map(currentConnection.()));

    console.log("subscribes change hua..", subscribers?.size);
    return () => {
      currentConnection.off(
        CurrentConnectionEvents.Subscribers,
        setSubscribers,
      );
    };
  }, [currentConnection]);

  const videoBoxes: Array<JSX.Element> = [];
  subscribers?.forEach((participant) => {
    const tracks = participant
      .getTrackPublications()
      .filter((track) => (track.source === Track.Source.Camera || track.source === Track.Source.Microphone));

    if (tracks.length) {
      const elm = <VideoBox participant={participant} />;

      videoBoxes.push(elm);
    }
  });

  return (
    <div ref={parentR} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <GridLayout
        parentRef={parentR}
        participants={videoBoxes}
        total={videoBoxes.length}
      />
      {/* <AudioElements /> */}
    </div>
  );
};

export default AudioVideoLayout;
