import React, { useState, useEffect } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  Box,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import {
  deviceManagerAtom,
  updateSelectedAudioInputDevice,
  updateSelectedAudioOutputDevice,
  updateSelectedVideoInputDevice,
  updateVideoResultion,
} from "../../../store/session";
import { useAtomValue } from "jotai";

import { getMediaServerConn } from "../../../utils/livekitClient";
import { Mic, Videocam, VolumeUp } from "@mui/icons-material";
import { VideoPresets } from "livekit-client";

export const VideoSettings = () => {
  const deviceManger = useAtomValue(deviceManagerAtom);

  const selectedVideoInput = deviceManger.selectedVideoInput;
  const selectedResolution = deviceManger.resolution;
  //   const selectedAudioOutput = deviceManger.selectedAudioOutput;

  const videoDevices = deviceManger.videoDevices;

  const conn = getMediaServerConn();

  const handleDeviceChange = (deviceId: string) => {
    updateSelectedVideoInputDevice(deviceId);
    conn.updateDeviceId("videoinput", deviceId);
  };

  const handleResolutionChange = (resulution:string) => {
    updateVideoResultion(resulution);
    
  }

  const presetKeys = Object.keys(VideoPresets) as Array<
    keyof typeof VideoPresets
  >;

  return (
    <Box>
      {/* Language Selector - Title and Select Side by Side */}
      <Box mb={4} display="flex" flexDirection="column" gap={4}>
        <Box>
          <Typography variant="body1">Camera</Typography>
          <FormControl size="small">
            <Select
              id="audio-output-device-select"
              value={selectedVideoInput}
              size="small"
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <Videocam />
                    </InputAdornment>
                  }
                />
              }
              onChange={(e) => handleDeviceChange(e.target.value)}
            >
              {videoDevices.length > 0 ? (
                videoDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label || `Camera ${device.deviceId}`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No speaker devices found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>

        <Box>
          <Typography variant="body1">Resolution</Typography>
          <FormControl size="small">
            <Select
              id="audio-output-device-select"
              value={selectedResolution}
              size="small"
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <Videocam />
                    </InputAdornment>
                  }
                />
              }
              onChange={(e) => handleResolutionChange(e.target.value)}
            >
              {presetKeys.map((presetKey) => {
                const preset = VideoPresets[presetKey];
                return (
                  <MenuItem key={presetKey} value={presetKey}>
                    {`${preset.width} x ${preset.height}`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default VideoSettings;
