import { useEffect, useState } from "react";
import {
  getMediaServerConn,
  getMediaServerConnRoom,
} from "../../../utils/livekitClient";
import { createLocalAudioTrack, Room } from "livekit-client";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import Mic from "@mui/icons-material/Mic";
import MicOff from "@mui/icons-material/MicOff";
import React from "react";
import { getAudioPreset, getDevices } from "../../../helpers/utils";
import { useAtom, useAtomValue } from "jotai";
import { participantListAtom } from "../../../store/participantList";
import { currentSessionAtom, deviceManagerAtom, openDeviceListAtom, updateAudioDevices, updateSelectedAudioInputDevice, updateSelectedAudioOutputDevice } from "../../../store/session";
import { ArrowDropUp, VolumeUp } from "@mui/icons-material";
import { appJotaiStore } from "../../../app-jotai";

function AudioIcon() {
  const currentSession = useAtomValue(currentSessionAtom);
  // const currentUser = useAtomValue(participantListAtom).get(currentSession?.me.userId!);
  const [loading, setLoading] = useState(false);
  const conn = getMediaServerConn();
  // const [showDeviceSelector, setShowDeviceSelector] = useState(false); // State to show/hide device selector div
  // const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([]);
  // const [audioOutputDevices, setAudioOutputDevices] = useState<MediaDeviceInfo[]>([]);

  const deviceManger = useAtomValue(deviceManagerAtom);

  const [openDeviceList, setOpenDeviceList] = useAtom(openDeviceListAtom); 

  const selectedAudioInput = deviceManger.selectedAudioInput 
  const selectedAudioOutput = deviceManger.selectedAudioOutput

  const audioDevices = deviceManger.audioDevices
  const audioOutputDevices = deviceManger.audioOutputDevices



  
  // const [selectedAudioInput, setSelectedAudioInput] = useState<string>("");
  // const [selectedAudioOutput, setSelectedAudioOutput] = useState<string>("");




  // useEffect(() => {
  //   const selectedAudioInput = localStorage.getItem("selectedAudioInput") ?? "";
  //   setSelectedAudioInput(selectedAudioInput);

  //   const selectedAudioOutput = localStorage.getItem("selectedAudioOutput") ?? "";
  //   setSelectedAudioOutput(selectedAudioOutput);

  //   // const fetchAudioInputDevices = async () => {
  //   //   const audioInputDevices =await Room.getLocalDevices("audioinput");
  //   //   updateAudioDevices(audioInputDevices);
  //   // };

  //   // const fetchAudioOutputDevices = async () => {
  //   //   const audioInputDevices = await Room.getLocalDevices("audiooutput");
  //   //   setAudioOutputDevices(audioInputDevices);
  //   // };

  //   fetchAudioInputDevices();
  //   fetchAudioOutputDevices();
  // }, []);

  useEffect(() => {
    const check = async () => {
      if (currentSession?.me.controls?.audio) {
        if (currentSession.me.audioEnabled) {
          console.log("needs to publish the audio...");
          await conn.publishTrack(true, false);
        }
      } else if (!currentSession?.me.controls?.audio) {
        if (currentSession?.me.audioEnabled) {
          // need to unpublish the cam as permission revoked.
          console.log("need to unpublish cam as permission revoked.");
          conn.unpublishLocalAudioTrack();
        }
      }
    };

    check();
  }, [currentSession?.me.controls?.audio]);

  const toggleAudio = async () => {
    setLoading(true);

    if (currentSession?.me?.audioEnabled) {
      if (currentSession.me.isAudioMuted) {
        conn.unmuteAudio();
      } else {
        conn.muteAudio();
      }
    } else {
      conn.publishTrack(true, false);
    }

    setLoading(false);
  };

  const toggleDeviceSelector = () => {
    setOpenDeviceList(openDeviceList === 'audio' ? null : 'audio');
  };

  const handleDeviceChange = (kind:string ,deviceId: string) => {
    if(kind == "audio") {
      updateSelectedAudioInputDevice(deviceId);
      conn.updateDeviceId("audioinput",deviceId);
    }else {
      updateSelectedAudioOutputDevice(deviceId);
      conn.updateDeviceId("audiooutput",deviceId);

    }
  };

  return (
    <>
      <Box
      sx={{
        // backgroundColor: "#ddd", // Shared background color for both icons
        borderRadius: "50px", // Rounded corners
        // padding: "4px", // Padding around the icons
      }}
      display="flex" alignItems="center">
        <IconButton
          style={{
            // background: "#ccc",
            marginRight: "-10px", // Negative margin to attach the arrow to the mic icon
          }}
          onClick={toggleDeviceSelector}
          size="large"
        >
          <ArrowDropUp />
        </IconButton>

        <IconButton
          style={{
            // background: "#ccc",
          }}
          disabled={loading || !currentSession?.me?.controls?.audio}
          onClick={toggleAudio}
          size="large"
          color={
            currentSession?.me?.audioEnabled &&
            !currentSession.me.isAudioMuted
              ? "primary"
              : "error"
          }
        >
          {loading ? (
            <CircularProgress size={22} />
          ) : currentSession?.me?.audioEnabled &&
            !currentSession.me.isAudioMuted ? (
            <Mic />
          ) : (
            <MicOff />
          )}
        </IconButton>
      </Box>

      {openDeviceList == "audio" && (
        <Box
        display="flex"
        flexDirection="column"
          style={{
            position: "absolute",
            // backgroundColor: "#eee",
            padding: "10px",
            // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            bottom: "60px", // Position it 50px above the button
            // transform: "translateY(-100%)", // Move the box above the button
            zIndex: 1000,
            gap: "10px",
          }}
        >
          <FormControl size="small" fullWidth>
            <Select
              id="audio-device-select"
              value={selectedAudioInput}
              size="small"
              MenuProps={{
                anchorOrigin: {
                  vertical: "top", // Dropdown will anchor from the top of the select box
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "bottom", // Dropdown opens upwards, anchored to the bottom of the select box
                  horizontal: "center",
                },
                PaperProps: {
                  style: {
                    zIndex: 1300, // Ensure the dropdown appears above other elements
                  },
                },
                disableScrollLock: true, // Prevent scroll lock when dropdown opens
              }}
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <Mic />
                    </InputAdornment>
                  }
                />
              }
              onChange={(e)=>handleDeviceChange("audio",e.target.value)}
            >
              {audioDevices.length > 0 ? (
                audioDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label || `Microphone ${device.deviceId}`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No audio devices found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl size="small" fullWidth>
            <Select
              id="audio-output-device-select"
              value={selectedAudioOutput}
              size="small"
              MenuProps={{
                anchorOrigin: {
                  vertical: "top", // Dropdown will anchor from the top of the select box
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "bottom", // Dropdown opens upwards, anchored to the bottom of the select box
                  horizontal: "center",
                },
                PaperProps: {
                  style: {
                    zIndex: 1300, // Ensure the dropdown appears above other elements
                  },
                },
                disableScrollLock: true, // Prevent scroll lock when dropdown opens
              }}
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <VolumeUp />
                    </InputAdornment>
                  }
                />
              }
              onChange={(e)=>handleDeviceChange("",e.target.value)}
            >
              {audioOutputDevices.length > 0 ? (
                audioOutputDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label || `Speaker ${device.deviceId}`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No speaker devices found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  );
}

export default AudioIcon;
