import type {
  LocalParticipant,
  Participant,
  RemoteAudioTrack,
  RemoteParticipant,
} from "livekit-client";
import { Track } from "livekit-client";
import React, { useMemo } from "react";
import { useAtomValue } from "jotai";
import { participantListAtom } from "../../../../store/participantList";
import { VideoElement } from "./VideoElement";
import AudioElm from "../audio/audio";
import MicOff from "@mui/icons-material/MicOff";
import Mic from "@mui/icons-material/Mic";
import "../../index.scss";
import { Avatar } from "@mui/material";
import { currentSessionAtom } from "../../../../store/session";

interface VideoBoxProp {
  participant: Participant | LocalParticipant | RemoteParticipant;
}

export const VideoBox = ({ participant }: VideoBoxProp) => {
  const user = useAtomValue(participantListAtom).get(participant.identity);
  const currentUser = useAtomValue(currentSessionAtom);

  const renderVideoElms = useMemo(() => {
    if (!user) return null;

    const elements: Array<JSX.Element> = [];

    // Find the video track (Camera) and audio track (Microphone)
    let videoTrack;
    let audioTrack;

    for (const track of participant.videoTrackPublications.values()) {
      if (track.source === Track.Source.Camera) {
        videoTrack = track;
      }
    }

    for (const track of participant.audioTrackPublications.values()) {
      if (track.source === Track.Source.Microphone && track.audioTrack) {
        audioTrack = track.audioTrack as RemoteAudioTrack;
      }
    }

    const elm = (
      <div className="video-container" key={participant.identity}>

        {user.videoEnabled && !user.isVideoMuted ? (
          <>
            {videoTrack && <VideoElement key={videoTrack.trackSid} track={videoTrack} />}
            {audioTrack && (
              <AudioElm
                userId={participant.identity}
                audioTrack={audioTrack}
              />
            )}
          </>
        ) : user.audioEnabled ? (
          <div className="audio-placeholder">
            {audioTrack && (
              <>
              <Avatar sx={{height:"100px", width:"100px"}}>RS</Avatar>
               <AudioElm
                userId={participant.identity}
                audioTrack={audioTrack}
              />
              </>
             
            )}
          </div>
        ) : null}
        <div className="username">{user.name} {user.userId == currentUser?.me.userId && "(me)"}</div>

        <div className="mic-icon">
          {user.isAudioMuted ? <MicOff fontSize="small" /> : <Mic fontSize="small" />}
        </div>

        <div className="connection-quality">
          <span className="ml-1">{user.connectionQuality}</span>
        </div>
      </div>
    );

    elements.push(elm);
    return elements;
  }, [participant, user]);

  return <>{renderVideoElms}</>;
};
