import React, { useState, useEffect } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  Box,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import {
  deviceManagerAtom,
  updateSelectedAudioInputDevice,
  updateSelectedAudioOutputDevice,
} from "../../../store/session";
import { useAtomValue } from "jotai";

import { getMediaServerConn } from "../../../utils/livekitClient";
import { Mic, VolumeUp } from "@mui/icons-material";

export const AudioSettings = () => {
  const deviceManger = useAtomValue(deviceManagerAtom);

  const selectedAudioInput = deviceManger.selectedAudioInput;
  const selectedAudioOutput = deviceManger.selectedAudioOutput;

  const audioDevices = deviceManger.audioDevices;
  const audioOutputDevices = deviceManger.audioOutputDevices;

  const conn = getMediaServerConn();

  const handleDeviceChange = (kind: string, deviceId: string) => {
    if (kind == "audio") {
      updateSelectedAudioInputDevice(deviceId);
      conn.updateDeviceId("audioinput", deviceId);
    } else {
      updateSelectedAudioOutputDevice(deviceId);
      conn.updateDeviceId("audiooutput", deviceId);
    }
  };

  return (
    <Box>
      {/* Language Selector - Title and Select Side by Side */}
      <Box mb={4} display="flex" flexDirection="column" gap={4}>
        <Box>
          <Typography variant="body1">Microphone</Typography>
          <FormControl>
            <Select
              id="audio-device-select"
              value={selectedAudioInput}
              size="small"
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <Mic />
                    </InputAdornment>
                  }
                />
              }
              onChange={(e) => handleDeviceChange("audio", e.target.value)}
            >
              {audioDevices.length > 0 ? (
                audioDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label || `Microphone ${device.deviceId}`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No audio devices found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>

        <Box>
          <Typography variant="body1">Speaker</Typography>
          <FormControl>
            <Select
              id="audio-output-device-select"
              value={selectedAudioOutput}
              size="small"
              input={
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <VolumeUp />
                    </InputAdornment>
                  }
                />
              }
              onChange={(e) => handleDeviceChange("", e.target.value)}
            >
              {audioOutputDevices.length > 0 ? (
                audioOutputDevices.map((device) => (
                  <MenuItem key={device.deviceId} value={device.deviceId}>
                    {device.label || `Speaker ${device.deviceId}`}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No speaker devices found</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default AudioSettings;
