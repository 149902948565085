import type {
  AudioCaptureOptions,
  ConnectionQuality,
  DisconnectReason,
  LocalParticipant,
  LocalTrackPublication,
  Participant,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  RoomOptions,
  TrackPublication,
  VideoCaptureOptions,
} from "livekit-client";

import {
  ConnectionState,
  createLocalTracks,
  Room,
  RoomEvent,
  supportsAV1,
  supportsVP9,
  Track,
  VideoPresets,
} from "livekit-client";

import { getParticipant, updateParticipant } from "../store/participantList";
import EventEmitter from "eventemitter3";
import { syncData } from "./roomClient";
import { appJotaiStore } from "../app-jotai";
import { enqueueSnackbar } from "notistack";
import { getAudioPreset, getWebcamResolution } from "../helpers/utils";
import { deviceManagerAtom } from "../store/session";
import { Tab } from "../types/tabs";
import { getTab } from "../store/tabList";

export enum CurrentConnectionEvents {
  ScreenShareStatus = "screenShareStatus",
  VideoStatus = "videoStatus",
  AudioSubscribers = "audioSubscribers",
  Subscribers = "subscribers",
  VideoSubscribers = "videoSubscribers",
  ScreenShareTracks = "screenShareTracks",
  ScreenSharedAdded = "screenShareAdded",
  ScreenShareRemoved = "screenShareRemoved",
}

class LivekitClient extends EventEmitter {
  // private _audioSubscribersMap = new Map<string, RemoteParticipant>();
  private _subscribersMap = new Map<
    string,
    LocalParticipant | RemoteParticipant
  >();

  private _screenShareTracksMap = new Map<
    string,
    Array<LocalTrackPublication | RemoteTrackPublication>
  >();

  private readonly token: string;
  private readonly url: string;

  public readonly room: Room;
  private connectingPromise: Promise<void> | null = null;

  private roomId: string;
  private userId: string;

  constructor(roomId: string, userId: string, token: string, url: string) {
    super();

    this.token = token;
    this.url = url;

    this.room = this.configureRoom();
    this.roomId = roomId;
    this.userId = userId;

    // Ensure that `this` is bound correctly for methods that are event handlers
    this.addSubscriber = this.addSubscriber.bind(this);
    this.addVideoSubscriber = this.addVideoSubscriber.bind(this);
    this.removeSubscriber = this.removeSubscriber.bind(this);
    this.trackSubscribed = this.trackSubscribed.bind(this);
    this.trackUnsubscribed = this.trackUnsubscribed.bind(this);
    this.trackMuted = this.trackMuted.bind(this);
    this.trackUnmuted = this.trackUnmuted.bind(this);
  }

  private configureRoom = () => {
    let videoCodec = (window as any).VIDEO_CODEC ?? "vp8";
    if (
      (videoCodec === "vp9" && !supportsVP9()) ||
      (videoCodec === "av1" && !supportsAV1())
    ) {
      videoCodec = "vp8";
    }

    const audioOutput =
      appJotaiStore.get(deviceManagerAtom)?.selectedAudioOutput;

    const roomOpts: RoomOptions = {
      adaptiveStream: true,
      stopLocalTrackOnUnpublish: true,
      videoCaptureDefaults: {
        resolution: VideoPresets.h360.resolution,
      },
      publishDefaults: {
        stopMicTrackOnMute: true,
        videoCodec,
      },
      audioOutput: { deviceId: audioOutput },
    };

    const room = new Room(roomOpts);

    room.prepareConnection(this.url, this.token);

    // Event listeners should reference methods that are properly bound
    room.on(RoomEvent.Reconnecting, () => {
      console.log("disconnected.. reconnecting");
      enqueueSnackbar("Livekit reconnecting", { variant: "warning" });
    });

    room.on(RoomEvent.Reconnected, () => {
      console.log("reconnected.");
      enqueueSnackbar("Livekit reconnected", { variant: "info" });
    });

    room.on(RoomEvent.Connected, () => {
      enqueueSnackbar("Livekit connected successfully", { variant: "success" });
      this.initiateParticipants();
    });

    room.on(RoomEvent.Disconnected, this.onDisconnected);

    room.on(RoomEvent.MediaDevicesError, this.mediaDevicesError);

    room.on(RoomEvent.ConnectionQualityChanged, this.connectionQualityChanged);

    room.on(RoomEvent.LocalTrackPublished, this.addSubscriber);

    room.on(RoomEvent.LocalTrackUnpublished, this.removeSubscriber);

    room.on(RoomEvent.TrackSubscribed, this.trackSubscribed);

    room.on(RoomEvent.TrackUnsubscribed, this.trackUnsubscribed);
    room.on(RoomEvent.TrackSubscriptionFailed, this.trackSubscriptionFailed);

    room.on(RoomEvent.TrackMuted, this.trackMuted);
    room.on(RoomEvent.TrackUnmuted, this.trackUnmuted);

    return room;
  };

  public getScreenTrack = (userId: string) => {
    console.log(
      "screen tracks presents for ",
      this._screenShareTracksMap.size,
      " keys::",
      this._screenShareTracksMap.keys(),
      "and userId..",
      userId,
    );
    return this._screenShareTracksMap.get(userId);
  };

  public removeScreenTrack = (userId: string) => {
    console.log("yha kya hai userId.....", this.userId);
    if (userId == this.userId) {
      console.log("yhahahhahhaahah");
      this.room.localParticipant.trackPublications.forEach((publication) => {
        if (
          publication.track &&
          (publication.source === Track.Source.ScreenShare ||
            publication.source === Track.Source.ScreenShareAudio)
        ) {
          this.room.localParticipant.unpublishTrack(publication.track, true);
        }
      });
    }
  };

  // Locking mechanism for connection
  public async connect(): Promise<void> {
    if (this.room.state === ConnectionState.Connected) {
      console.log("Already connected to LiveKit.");
      return;
    }

    if (this.connectingPromise) {
      console.log("Connection already in progress. Waiting for it to finish.");
      return this.connectingPromise;
    }

    this.connectingPromise = new Promise(async (resolve, reject) => {
      try {
        console.log("Connecting to LiveKit...");
        await this.room.connect(this.url, this.token);
        await this.initiateParticipants();
        console.log("Connected to LiveKit successfully.");
        resolve();
      } catch (error: any) {
        console.error("Error while connecting to LiveKit:", error);

        reject(error);
      } finally {
        this.connectingPromise = null; // Reset the promise after connection attempt finishes
      }
    });

    return this.connectingPromise;
  }

  private initiateParticipants = async () => {
    this.room.remoteParticipants.forEach((participant) => {
      participant.getTrackPublications().forEach((track) => {
        if (track.isSubscribed) {
          this.addSubscriber(track as RemoteTrackPublication, participant);
          // if (
          //   track.source === Track.Source.ScreenShare ||
          //   track.source === Track.Source.ScreenShareAudio
          // ) {

          //   updateParticipant(participant.identity,{
          //     screenShareTracks:1
          //   })
          //   this.addScreenShareTrack(
          //     participant.identity,
          //     track as RemoteTrackPublication,
          //   );
          // } else if (track.source === Track.Source.Camera) {
          //   console.log("Video of a participant available.");
          //   this.addVideoSubscriber(participant);
          // }
        }
      });
    });
  };

  private addSubscriber = (
    track: LocalTrackPublication | RemoteTrackPublication,
    participant: LocalParticipant | RemoteParticipant,
  ) => {
    console.log("track published....");

    if (
      track.source === Track.Source.ScreenShare
      // track.source === Track.Source.ScreenShareAudio
    ) {
      // updateParticipant(participant.identity, { screenShareTracks: 1 });

      this.addScreenShareTrack(participant.identity, track);
    } else if (track.source === Track.Source.Microphone) {
      this.addAudioSubscriber(participant);

      const count = participant
        .getTrackPublications()
        .filter((track) => track.source === Track.Source.Microphone).length;

      const p = updateParticipant(participant.identity, {
        // isMuted: track.audioTrack?.isMuted ?? false,
        // audioTracks: count === 0 ? 1 : count,
        // isPublished: true,
        audioEnabled: !track.isMuted,
        isAudioMuted: track?.isMuted ?? false,
      });

      if (p && p.isLocal) {
        syncData.UserMuteStatus(this.roomId, this.userId, p);
      }
    }

    if (track.source === Track.Source.Camera) {
      console.log("adding video subscriber");
      this.addVideoSubscriber(participant);
      const count = participant
        .getTrackPublications()
        .filter((track) => track.source === Track.Source.Camera).length;

      const p = updateParticipant(participant.identity, {
        videoEnabled: !track.isMuted,
        // videoTracks: count === 0 ? 1 : count,
        isVideoMuted: track?.isMuted ?? false,
      });

      if (p && p.isLocal) {
        syncData.UserMuteStatus(this.roomId, this.userId, p);
      }
    }
  };

  private async addScreenShareTrack(
    userId: string,
    track: LocalTrackPublication | RemoteTrackPublication,
  ) {
    const existUser = getParticipant(userId);
    console.log("current user.. . ", existUser);
    if (!existUser) {
      return;
    }
    console.log("adding in screen track map...");

    const tracks: Array<LocalTrackPublication | RemoteTrackPublication> = [];
    if (this._screenShareTracksMap.has(userId)) {
      const oldTracks = this._screenShareTracksMap.get(userId);
      if (oldTracks && oldTracks.length) {
        tracks.push(...oldTracks);
      }
    }
    tracks.push(track);
    this._screenShareTracksMap.set(userId, tracks);
    // this.syncScreenShareTracks(userId);
    if (userId == this.room.localParticipant.identity) {
      console.log("local track ....  needs to publish");
      console.log("screen track added message sent..");

      const tab: Tab = {
        tabId: userId,
        type: "screen",
        title: userId + " screen",
      };
      syncData.createTab(this.roomId, this.userId, tab);

      syncData.switchTab(this.roomId, this.userId, tab);
    }
    this.emit(CurrentConnectionEvents.ScreenSharedAdded, userId);
  }

  public removeScreenShareTrack = (userId: string) => {
    this._screenShareTracksMap.delete(userId);
    this.syncScreenShareTracks(userId);
    const tab = getTab(userId);

    if (tab) {
      syncData.deleteTab(this.roomId, this.userId, tab);
    }
    this.emit(CurrentConnectionEvents.ScreenShareRemoved, userId);
  };

  public muteAudio = () => {
    // if(userId == this.userId) {
    // my track.. unpublish it.
    this.room.localParticipant.audioTrackPublications.forEach((track) => {
      if (track.source === Track.Source.Microphone) {
        track.mute();
      }
    });
    // }
  };

  public unmuteAudio = () => {
    // if(userId == this.userId) {
    this.room.localParticipant.audioTrackPublications.forEach((track) => {
      if (track.source === Track.Source.Microphone) {
        track.unmute();
      }
    });
    // }
  };

  public unmuteVideo = () => {
    // if(userId == this.userId) {
    this.room.localParticipant.videoTrackPublications.forEach((track) => {
      if (track.source === Track.Source.Camera) {
        track.unmute();
      }
    });
    // }
  };

  public muteVideo = () => {
    // if(userId == this.userId) {
    this.room.localParticipant.videoTrackPublications.forEach((track) => {
      if (track.source === Track.Source.Camera) {
        track.mute();
      }
    });
    // }
  };

  public syncScreenShareTracks(userId: string) {
    console.log("screen come for ", userId);
    // notify about status
    // if (this._screenShareTracksMap.size) {
    //   payload = {
    //     isActive: true,
    //     sharedBy: userId,
    //   };
    //   this.emit(CurrentConnectionEvents.ScreenShareStatus, true);
    // } else {
    //   this.emit(CurrentConnectionEvents.ScreenShareStatus, false);
    // }

    // emit a new tracks map
    const screenShareTracks = new Map(this._screenShareTracksMap) as any;
    this.emit(CurrentConnectionEvents.ScreenShareTracks, screenShareTracks);
  }

  public addAudioSubscriber = (
    participant: Participant | LocalParticipant | RemoteParticipant,
  ) => {
    if (!participant.audioTrackPublications.size) {
      return;
    }

    const existUser = getParticipant(participant.identity);

    if (!existUser) {
      return;
    }

    // if (participant.identity == this.room?.localParticipant.identity) {
    //   return;
    // }

    this._subscribersMap.set(
      participant.identity,
      participant as RemoteParticipant,
    );

    this.syncSubscribers();
  };

  private syncSubscribers = () => {
    console.log("emit kr diya video subscriber...");
    const subscribers = new Map(this._subscribersMap) as any;
    this.emit(CurrentConnectionEvents.Subscribers, subscribers);
  };

  public getSubscribers = (): Map<
    string,
    Participant | LocalParticipant | RemoteParticipant
  > => {
    return this._subscribersMap;
  };

  // public getVideoSubscribers = (): Map<
  //   string,
  //   Participant | LocalParticipant | RemoteParticipant
  // > => {
  //   return this._videoSubscribersMap;
  // };

  // public getAudioSubscribers = (): Map<
  //   string,
  //   Participant | LocalParticipant | RemoteParticipant
  // > => {
  //   return this._audioSubscribersMap;
  // };

  public addVideoSubscriber = (
    participant: Participant | LocalParticipant | RemoteParticipant,
  ) => {
    if (!participant.videoTrackPublications.size) {
      return;
    }

    const existUser = getParticipant(participant.identity);

    console.log("exisiting..", existUser);
    if (!existUser) {
      return;
    }

    this._subscribersMap.set(
      participant.identity,
      participant as RemoteParticipant,
    );
    console.log("map me set kr diya.. ab sync krne jaa rha....");

    this.syncSubscribers();
  };

  // public syncVideoSubscribers = () => {
  //   const subscribers = new Map(this._videoSubscribersMap) as any;
  //   this.emit(CurrentConnectionEvents.VideoSubscribers, subscribers);

  //   console.log("ye call ho rha hai..");
  // };

  // private syncAudioSubscribers = () => {
  //   const audioSubscribers = new Map(this._audioSubscribersMap) as any;
  //   this.emit(CurrentConnectionEvents.AudioSubscribers, audioSubscribers);
  // };

  private removeSubscriber = (
    track: LocalTrackPublication | RemoteTrackPublication,
    participant: LocalParticipant | RemoteParticipant,
  ) => {
    if (
      track.source === Track.Source.ScreenShare ||
      track.source === Track.Source.ScreenShareAudio
    ) {
      // updateParticipant(participant.identity, { screenShareTracks: 0 });

      this.removeScreenShareTrack(participant.identity);
    }

    if (track.source === Track.Source.Microphone) {
      this.removeAudioSubscriber(participant.identity);

      updateParticipant(participant.identity, {
        audioEnabled: false,
        // isMuted: track.audioTrack?.isMuted ?? false,
        isAudioMuted: track.audioTrack?.isMuted ?? false,
        // audioTracks:
        //   participant
        //     .getTrackPublications()
        //     .filter((track) => track.source === Track.Source.Microphone)
        //     .length ?? 0,
      });
    }

    if (track.source === Track.Source.Camera) {
      this.removeVideoSubscriber(participant.identity);

      updateParticipant(participant.identity, {
        // videoTracks:
        //   participant
        //     .getTrackPublications()
        //     .filter((track) => track.source === Track.Source.Camera).length ??
        //   0,
        videoEnabled: false,
      });
    }
  };

  public removeAudioSubscriber(userId: string) {
    if (!this._subscribersMap.has(userId)) {
      return;
    }

    if (!this._subscribersMap.get(userId)?.videoTrackPublications) {
      this._subscribersMap.delete(userId);
      this.syncSubscribers();
    }

    // this._audioSubscribersMap.delete(userId);
    // this.syncAudioSubscribers();
  }

  public removeVideoSubscriber(userId: string) {
    if (!this._subscribersMap.has(userId)) {
      return;
    }

    if (!this._subscribersMap.get(userId)?.audioTrackPublications) {
      this._subscribersMap.delete(userId);
      this.syncSubscribers();
    }

    // this._videoSubscribersMap.delete(userId);
    // this.syncVideoSubscribers();
  }

  private trackSubscribed = (
    _: RemoteTrack,
    track: RemoteTrackPublication,
    participant: RemoteParticipant,
  ) => {
    console.log("Track subscribed.");
    this.addSubscriber(track, participant);
  };

  private trackUnsubscribed = (
    _: RemoteTrack,
    track: RemoteTrackPublication,
    participant: RemoteParticipant,
  ) => {
    console.log("Track unsubscribed.");
    this.removeSubscriber(track, participant);
  };

  private trackSubscriptionFailed = (
    trackSid: string,
    participant: RemoteParticipant,
  ) => {
    console.log(
      `Track subscription failed for ${participant.name}, trackSid: ${trackSid}`,
    );
  };

  private trackMuted = (track: TrackPublication, participant: Participant) => {
    console.log("Track muted.");

    if (track.source === Track.Source.Microphone) {
      updateParticipant(participant.identity, {
        isAudioMuted: true,
      });
    } else if (track.source === Track.Source.Camera) {
      updateParticipant(participant.identity, {
        isVideoMuted: true,
      });
    }
  };

  private trackUnmuted = (
    track: TrackPublication,
    participant: Participant,
  ) => {
    console.log("Track unmuted.");
    if (track.source === Track.Source.Microphone) {
      updateParticipant(participant.identity, {
        isAudioMuted: false,
      });
    } else if (track.source === Track.Source.Camera) {
      updateParticipant(participant.identity, {
        isVideoMuted: false,
      });
    }
  };

  private onDisconnected = (reason?: DisconnectReason | undefined) => {
    console.log("LiveKit disconnected:", reason);
    enqueueSnackbar("Livekit disconnected");
  };

  private mediaDevicesError = (error: Error) => {
    console.error("Media devices error:", error);
    enqueueSnackbar("Livekit media device error", { variant: "error" });
  };

  private async connectionQualityChanged(
    connectionQuality: ConnectionQuality,
    participant: Participant,
  ) {
    console.log(
      `Connection quality changed for participant: ${participant.identity}, quality: ${connectionQuality}`,
    );

    updateParticipant(participant.identity, {
      connectionQuality,
    });
  }

  public async updateResolution(resolution: string) {
    await this.room.localParticipant.videoTrackPublications.forEach((track) => {
      if (track.source == Track.Source.Camera) {
        track.videoTrack?.restartTrack({
          resolution: getWebcamResolution(resolution),
        });
      }
    });
  }

  public async updateDeviceId(kind: MediaDeviceKind, deviceId: string) {
    console.log("changing device Id: ", kind, " id: ", deviceId);
    await this.room.switchActiveDevice(kind, deviceId);
  }

  public async publishTrack(audio: boolean, video: boolean) {
    const selectedAudioInput =
      appJotaiStore.get(deviceManagerAtom)?.selectedAudioInput;
    const selectedVideoInput =
      appJotaiStore.get(deviceManagerAtom)?.selectedVideoInput;
    const resolution = appJotaiStore.get(deviceManagerAtom)?.resolution ?? "";

    const videoMedia: VideoCaptureOptions = {};
    if (video) {
      videoMedia.deviceId = {
        exact: selectedVideoInput,
        ideal: selectedVideoInput,
      };

      videoMedia.resolution = getWebcamResolution(resolution);
    }


    const audioMedia: AudioCaptureOptions = {};
    if (audio) {
      audioMedia.deviceId = selectedAudioInput;
    }

    const tracks = await createLocalTracks({
      audio: audio ? audioMedia : audio,
      video: video ? videoMedia : video,
    });

    tracks.forEach((track) => {
      if (track.source == Track.Source.Microphone) {
        this.room.localParticipant.publishTrack(track, {
          audioPreset: getAudioPreset(),
        });
      } else {
        this.room.localParticipant.publishTrack(track);
      }
    });
  }

  public async unpublishLocalAudioTrack() {
    this.room.localParticipant.videoTrackPublications.forEach((track) => {
      if (track.track && track.source === Track.Source.Microphone) {
        this.room.localParticipant.unpublishTrack(track.track);
      }
    });
  }

  public async unpublishLocalScreeTrack() {
    this.room.localParticipant.videoTrackPublications.forEach((track) => {
      if (
        track.track &&
        (track.source === Track.Source.ScreenShare ||
          track.source === Track.Source.ScreenShareAudio)
      ) {
        this.room.localParticipant.unpublishTrack(track.track);
      }
    });
  }

  public async unpublishLocalVideoTrack() {
    this.room.localParticipant.videoTrackPublications.forEach((track) => {
      if (track.track && track.source === Track.Source.Camera) {
        this.room.localParticipant.unpublishTrack(track.track);
      }
    });
  }
}

let currentConnect: LivekitClient | null = null;

export const createLivekitConnection = (
  roomId: string,
  userId: string,
  url: string,
  token: string,
): LivekitClient => {
  if (!currentConnect) {
    currentConnect = new LivekitClient(roomId, userId, token, url);
  }
  return currentConnect;
};

export const getMediaServerConn = (): LivekitClient => {
  if (!currentConnect) {
    throw new Error("Connection not created yet.");
  }
  return currentConnect;
};

export const getMediaServerConnRoom = (): Room => {
  return getMediaServerConn().room;
};
