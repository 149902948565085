import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  Grid,
  ListItemText,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Import close icon
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import './FileModal.scss'; // Import the SCSS file
import { useAtom, useAtomValue } from "jotai";
import { currentSessionAtom, isFileModalOpen, updateWhiteboardFile } from "../../store/session";
import { fetchConvertedFiles, fetchRecentFiles, getPresignedUrl, requestFileConversion } from "../../api";
import { enqueueSnackbar } from "notistack";

// Define the type for recent files
interface FileItem {
  title: string;
  thumbnail: { url: string; };
  type: "pdf" | "image" | "doc" | "default";
  rootUrl: string;
  fileId: string;
  status: string;
}

// Helper function to get file icons based on file type
const getFileIcon = (type: string) => {
  switch (type) {
    case "pdf":
      return <PictureAsPdfIcon fontSize="medium" />;
    case "image":
      return <ImageIcon fontSize="medium" />;
    case "doc":
      return <DescriptionIcon fontSize="medium" />;
    default:
      return <InsertDriveFileIcon fontSize="medium" />;
  }
};

// Modal component
const FileModal = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(isFileModalOpen);
  const session = useAtomValue(currentSessionAtom);
  const [tabValue, setTabValue] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [recentFiles, setRecentFiles] = useState<FileItem[]>([]);

  const fileType = [
    "m4a", "mp3", "wav", "wma", "mp4", "mkv", "wmv", "webm", "mov", "avi",
    "pptx", "ppt", "doc", "docx", "xls", "xlsx", "odt", "pdf",
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];

      getPresignedUrl("userId", file.name, file.type).then((response) => {
        const fileId = response.fileId;
        const fileKey = response.key;
        let formData = new FormData();
        formData.append("key", response.key);
        formData.append("acl", "public-read");
        formData.append("Content-Type", file.type);
        formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
        formData.append("X-Amz-Credential", response.credential);
        formData.append("X-Amz-Date", response.date);
        formData.append("Policy", response.policy);
        formData.append("X-Amz-Signature", response.signature);
        formData.append("file", file);

        fetch(response.action, { method: "POST", body: formData })
          .then((response) => {
            if (response.status === 204) {
              enqueueSnackbar("File uploaded.", { variant: "success" });
              requestFileConversion(
                session?.me.userId!,
                fileId,
                file.name,
                file.type,
                fileKey
              ).then(() => {
                enqueueSnackbar("File conversion is in progress.", { variant: "warning" });
                updateWhiteboardFile(fileId);
              });
            }
          })
          .catch((error) => console.log("Error during file upload:", error));
      });
    }
  };

  const handleClose = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleFileClick = (file: FileItem) => {
    console.log("File clicked:", file.title);
    updateWhiteboardFile(file.fileId);
  };

  useEffect(() => {
    if (isModalOpen) {
      setLoading(true);
      fetchRecentFiles().then((response) => {
        setRecentFiles(response);
        setLoading(false);
      });
    }
  }, [isModalOpen]);

  return (
    <Dialog open={isModalOpen} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>
        Select a File
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          style={{ position: "absolute", right: 16, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ minHeight: "500px" }}>
        <Tabs value={tabValue} onChange={handleTabChange} style={{ marginBottom: "16px" }}>
          <Tab label="Recent Files" />
          <Tab label="Upload File" />
        </Tabs>

        {tabValue === 0 && (
          <Box p={2}>
            {loading ? (
              <div className="loading-spinner">
                <CircularProgress />
              </div>
            ) : (
              <Grid container spacing={3}>
                {recentFiles.length ? (
                  recentFiles.map((file, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index} onClick={() => handleFileClick(file)}>
                      <div className="file-container">
                        <img
                          className="file-thumbnail"
                          src={file.rootUrl +"/"+ file.thumbnail.url}
                          alt={file.title}
                        />
                        <div className="file-details">
                          <div className="file-icon">
                            {getFileIcon(file.type)}
                          </div>
                          <div className="file-title">{file.title}</div>
                        </div>
                        <Typography className="file-subtitle">
                          Status: {file.status}
                        </Typography>
                      </div>
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body1" className="no-files">
                    No recent files available.
                  </Typography>
                )}
              </Grid>
            )}
          </Box>
        )}

        {tabValue === 1 && (
          <Box p={2}>
            <input type="file" onChange={handleFileUpload} />
            <Button variant="contained" color="primary" className="upload-button">
              Upload
            </Button>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FileModal;
