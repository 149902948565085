import IconButton from "@mui/material/IconButton";

import React from "react";

import { useSetAtom } from "jotai";
import { isParticipantListVisibleAtom } from "../../../store/participantList";
import { Settings } from "@mui/icons-material";
import { isSettingsModalOpen } from "../../../store/session";

function SettingsIcon() {

  const togglePeopleList = useSetAtom(isSettingsModalOpen); // Set the atom to toggle visibility

  return (
    <IconButton
      style={{
        // border: "1px solid #ccc",
        // background: "#ccc",
        marginLeft: "5px",
      }}
      size="large"
      onClick={() => togglePeopleList(prev => !prev)} // Toggle the visibility 
    >
      <Settings />
      
    </IconButton>
  );
}

export default SettingsIcon;