import polyfill from "../../../packages/excalidraw/polyfill";
import { useCallback, useEffect, useRef, useState } from "react";
import { getDefaultAppState } from "../../../packages/excalidraw/appState";
import { ErrorDialog } from "../../../packages/excalidraw/components/ErrorDialog";
import { EVENT } from "../../../packages/excalidraw/constants";
import type {
  ExcalidrawElement,
  ExcalidrawImageElement,
  FileId,
  NonDeletedExcalidrawElement,
  OrderedExcalidrawElement,
} from "../../../packages/excalidraw/element/types";
import { useCallbackRefState } from "../../../packages/excalidraw/hooks/useCallbackRefState";
import { t } from "../../../packages/excalidraw/i18n";
import {
  Excalidraw,
  LiveCollaborationTrigger,
  TTDDialogTrigger,
  StoreAction,
  reconcileElements,
  Footer,
  getSceneVersion,
  Button,
} from "../../../packages/excalidraw";

import { TabHeader } from "./_components/TabHeader";
import type {
  AppState,
  ExcalidrawImperativeAPI,
  BinaryFiles,
  ExcalidrawInitialDataState,
  UIAppState,
  BinaryFileData,
  DataURL,
} from "../../../packages/excalidraw/types";
import type { ResolvablePromise } from "../../../packages/excalidraw/utils";
import {
  getVersion,
  resolvablePromise,
} from "../../../packages/excalidraw/utils";

import type Collab from "./sync";
import { exportToBackend } from "./data";

import {
  restore,
  restoreAppState,
  restoreElements,
} from "../../../packages/excalidraw/data/restore";

import { updateStaleImageStatuses } from "./data/FileManager";

import {
  LibraryIndexedDBAdapter,
  LibraryLocalStorageMigrationAdapter,
  LocalData,
} from "./data/LocalData";
import {
  parseLibraryTokensFromUrl,
  useHandleLibrary,
} from "../../../packages/excalidraw/data/library";
import { AppMainMenu } from "./_components/AppMainMenu";
import { Provider, useAtom, useAtomValue } from "jotai";
import { appJotaiStore } from "./../../app-jotai";

import "./index.scss";
import type { ResolutionType } from "../../../packages/excalidraw/utility-types";

import type { RemoteExcalidrawElement } from "../../../packages/excalidraw/data/reconcile";

import { appThemeAtom, useHandleAppTheme } from "./../../useHandleAppTheme";
import { useAppLangCode } from "./../../app-language/language-state";

import { AIComponents } from "./_components/AI";
import React from "react";
import MqttService, { getMqttConn } from "../../utils/mqttClient";
import FooterUI from "./_components/FooterUI";
import { ImportedDataState } from "../../../packages/excalidraw/data/types";
import { loadFilesFromStorage, loadFromBackend } from "./data/storage";
import {
  addTab,
  addTabs,
  currentTabAtom,
  getTab,
  removeTab,
  setCurrentTab,
  tabListAtom,
  tabPoints,
  updateTab,
} from "../../store/tabList";

import { createCollab, fetchFileWithElm, prepareForExcalidraw } from "./sync";
import {
  CurrentConnectionEvents,
  getMediaServerConn,
} from "../../utils/livekitClient";
import { syncData } from "../../utils/roomClient";
import {
  LocalTrackPublication,
  RemoteTrackPublication,
  Track,
} from "livekit-client";
import {
  currentSessionAtom,
  externalMediaPlayerAtom,
  isFileModalOpen,
  whiteboardFileAtom,
} from "../../store/session";
import { ExternalVideoPlayer } from "../external-media";
import { ExternalWebpage } from "../webpage";
import { QuillEditor } from "../editor";
import * as pdfjsLib from "pdfjs-dist";

// import workerUrl from "pdfjs-dist/build/pdf.worker.min.mjs";
import { randomId, randomInteger } from "../../../packages/excalidraw/random";
import { Radians } from "../../../packages/math";
import usePreviousTab from "./hooks/usePreviousTab";
import { FileButton } from "../drive";
import { fetchConvertedFiles } from "../../api";
import { Tab } from "../../types/tabs";
import { enqueueSnackbar } from "notistack";
import { file } from "../../../packages/excalidraw/components/icons";
import { FilledButton } from "../../../packages/excalidraw/components/FilledButton";
import { ButtonIcon } from "../../../packages/excalidraw/components/ButtonIcon";
// pdfjsLib.GlobalWorkerOptions.workerSrc = workerUrl;

polyfill();

// window.EXCALIDRAW_THROTTLE_RENDER = true;

const initializeScene = async (opts: {
  collabAPI: Collab | null;
  excalidrawAPI: ExcalidrawImperativeAPI;
  viewMode: boolean;
  tabId?: string;
}): Promise<
  { scene: ExcalidrawInitialDataState | null } & (
    | { isExternalScene: true }
    | { isExternalScene: false }
  )
> => {
  const { excalidrawAPI, tabId } = opts;

  let scene:
    | (ImportedDataState & { elements: readonly OrderedExcalidrawElement[] })
    | null
    | undefined;
  if (tabId) {
    const currentTabPoints = tabPoints.get(tabId);

    console.log("current tab data...", currentTabPoints);

    if (currentTabPoints) {
      const elements = currentTabPoints ?? [];
      if (elements) {
        opts.collabAPI?.setLastBroadcastedOrReceivedSceneVersion(
          getSceneVersion(elements),
        );
        // console.log(opts.collabAPI?.getLastBroadcastedOrReceivedSceneVersion());
      }
      scene = {
        elements: elements,
        appState: {
          viewModeEnabled: opts.viewMode,
        },
        files: {},
      };
    }
  } else {
    console.log("this is first time load from database...");
    // scene = await opts?.collabAPI?.startCollaboration(opts.roomId);
  }

  console.log(
    "app statge.... view mode.. ",
    excalidrawAPI.getAppState().viewModeEnabled,
  );

  return {
    scene: {
      ...scene,
      appState: {
        ...restoreAppState(
          {
            ...scene?.appState,
            theme: "light",
          },
          excalidrawAPI.getAppState(),
        ),

        isLoading: false,
      },
      elements: reconcileElements(
        scene?.elements || [],
        excalidrawAPI.getSceneElementsIncludingDeleted() as RemoteExcalidrawElement[],
        excalidrawAPI.getAppState(),
      ),
    },
    isExternalScene: true,
  };
};


const ExcalidrawWrapper = () => {
  const [errorMessage, setErrorMessage] = useState("");

  // const [appTheme, setAppTheme] = useAtom(appThemeAtom);
  const [viewMode, setVideoMode] = useState(false);
  const { editorTheme } = useHandleAppTheme();

  // console.log("app theme...", appTheme);
  // console.log("editor theme...", editorTheme);


  const [langCode, setLangCode] = useAppLangCode();

  const initialStatePromiseRef = useRef<{
    promise: ResolvablePromise<ExcalidrawInitialDataState | null>;
  }>({ promise: null! });


  const excalidrawAPIRef = useRef<ExcalidrawImperativeAPI | null>(null);

  const excalidrawRefCallback = useCallback((api: ExcalidrawImperativeAPI | null) => {
    excalidrawAPIRef.current = api; // Always update the current ref
    console.log("excalidraw api updated...", excalidrawAPIRef.current?.id);

    if (!excalidrawAPIRef.current || !currentSession) {
      return;
    }

    console.log("excali draw api changed...", excalidrawAPIRef.current);
    console.log("excalidra verions:: ",excalidrawAPIRef.current?.id)

    const collab = createCollab({
      excalidrawAPI: excalidrawAPIRef.current,
      userId: currentSession?.me.userId!,
      roomId: currentSession?.roomId!,
    });

    const mqttConn = getMqttConn();

    mqttConn.setMessageHandler((topic, payload) => {
      if (
        topic == MqttService.getWhiteboardTopic(currentSession?.roomId!)
      ) {
        collab.onSubcribe(payload);
      }
    });
    setCollabAPI(collab);
    // excalidrawAPIRef.current?.id
  }, []);

  if (!initialStatePromiseRef.current.promise) {
    initialStatePromiseRef.current.promise =
      resolvablePromise<ExcalidrawInitialDataState | null>();
  }

  // const [excalidrawAPI, excalidrawRefCallback] =
  //   useCallbackRefState<ExcalidrawImperativeAPI>();


  const [collabAPI, setCollabAPI] = useState<Collab | null>(null);

  const currentSession = useAtomValue(currentSessionAtom);
  const currentExternalMediaPlayer = useAtomValue(externalMediaPlayerAtom);
  const whiteboardFile = useAtomValue(whiteboardFileAtom);
  const currentTab = useAtomValue(currentTabAtom);
  const previousTab = usePreviousTab(currentTab);

  const [screenVideoTrack, setScreenVideoTrack] = useState<
    LocalTrackPublication | RemoteTrackPublication | null
  >(null);

  const [externalVideoSrc, setexternalVideoSrc] = useState("");
  const [externalWebpage, setexternalWebpage] = useState("");

  const currentConnection = getMediaServerConn();
  useEffect(() => {
    console.log("calling tab data....");
    loadFromBackend(currentSession?.roomId!).then(
      ({ tabs, currentTabIndex }) => {
        console.log("tabs data...", tabs);
        // tabsData.forEach((tab) => {
        //   // Tabs.set(tab.tabId, tab);
        // });
        addTabs(tabs);

        // console.log("tabs repo...", Tabs);

        const firstTab = tabs[currentTabIndex];
        if (firstTab) {
          setCurrentTab(firstTab);
        }
      },
    );
  }, [currentSession?.roomId!]);

  useEffect(() => {
    console.log("vide mode hai ye...", currentSession?.me.controls?.write);

    setVideoMode(!currentSession?.me.controls?.write);
  }, [currentSession?.me.controls?.write]);

  useEffect(() => {
    currentConnection.on(
      CurrentConnectionEvents.ScreenSharedAdded,
      (userId) => {
        console.log(
          "screen share track detected. ",
          screenVideoTrack,
          "currentTab?.tabId == userId",
          currentTab?.tabId,
          " userID:",
          userId,
        );
        if (!screenVideoTrack && currentTab?.tabId == userId) {
          const tracks = currentConnection.getScreenTrack(userId);
          if (tracks) {
            for (let i = 0; i < tracks.length; i++) {
              if (tracks[i].source == Track.Source.ScreenShare) {
                console.log("screen track set kr diya event aane ke baad.");
                setScreenVideoTrack(tracks[i]);
              }
            }
          }
        }
      },
    );

    return () => {
      currentConnection.off(CurrentConnectionEvents.ScreenSharedAdded);
    };
  }, [currentConnection, currentTab]);






  useEffect(() => {
    if (!currentSession) {
      return;
    }

    if (previousTab) {
      savePreviousTab();
    }

    const loadImages = (
      data: ResolutionType<typeof initializeScene>,
      isInitialLoad = false,
    ) => {
      if (!data.scene || !excalidrawAPIRef.current) {
        return;
      }

      console.log("vide mode hai ye...", viewMode);

      if (data.scene.elements) {
        collabAPI
          ?.fetchImageFilesFromStorage({
            elements: data.scene.elements,
            forceFetchFiles: true,
          })
          .then(({ loadedFiles, erroredFiles }) => {
            excalidrawAPIRef.current?.addFiles(loadedFiles);
            
              updateStaleImageStatuses({
                excalidrawAPI: excalidrawAPIRef.current!,
                erroredFiles,
                elements: excalidrawAPIRef.current?.getSceneElementsIncludingDeleted()!,
              });
            
           
          });
      }
    };

    const loadScene = async () => {
      if (!excalidrawAPIRef.current) return;

      console.log("reset ho gya...")
      excalidrawAPIRef.current.resetScene();

      initializeScene({
        collabAPI,
        excalidrawAPI:excalidrawAPIRef.current,
        viewMode,
        tabId: currentTab?.tabId,
      }).then(async (data) => {
        loadImages(data, /* isInitialLoad */ true);
        console.log("initial data is showing...", data.scene);
        initialStatePromiseRef.current.promise.resolve(data.scene);
        excalidrawAPIRef.current?.updateScene({
          elements: data.scene?.elements,
        });
      });
    };

    if (currentTab?.type == "normal") {
      if (excalidrawAPIRef.current && collabAPI) {
        loadScene();
      }
    } else if (currentTab?.type == "screen") {
      console.log(
        "screen board created... checking screen track",
        currentTab.tabId,
      );

      const tracks = currentConnection.getScreenTrack(currentTab.tabId);
      if (tracks) {
        for (let i = 0; i < tracks.length; i++) {
          if (tracks[i].source == Track.Source.ScreenShare) {
            setScreenVideoTrack(tracks[i]);
          }
        }
      } else {
        console.log("track abhi ni milaa hai...");
      }
    } else if (currentTab?.type == "external-video") {
      setexternalVideoSrc(currentTab?.url ?? "");
    } else if (currentTab?.type == "webpage") {
      setexternalWebpage(currentTab?.url ?? "");
    } else if (currentTab?.type == "file") {
      // excalidrawAPI

      loadScene();
      // const url = Tabs.get(currentTab.tabId)?.url!;
      // fetchConvertedFiles(url).then(async (res) => {
      //   const jsonUrl = res.rootUrl + res.url;

      //   const jsonRes = await fetch(jsonUrl);

      //   const jsonData = await jsonRes.json();

      //   let pages: Page[] = [];

      //   for (let i = 0; i < jsonData.files.length; i++) {
      //     const page: Page = {
      //       points: [],
      //       index: i,
      //       url: jsonData.files[i],
      //     };
      //     pages.push(page);
      //   }
      //   const tab: Tab = {
      //     type: currentTab.type,
      //     pages: pages,
      //     tabId: currentTab.tabId,
      //     url: url,
      //   };
      //   Tabs.set(tab.tabId, tab);

      //   console.log("tab set ho gya...", tab);

      //   loadScene();
      //   // setCurrentTab(currentTab);
      // });

      console.log("file type tab hai...");
    } else {
      console.log("koi unknown tab aayam,,,", currentTab);
    }

    const onHashChange = async (event: HashChangeEvent) => {
      if (!currentTab || !excalidrawAPIRef.current) {
        return;
      }
      event.preventDefault();
      const libraryUrlTokens = parseLibraryTokensFromUrl();
      if (!libraryUrlTokens) {
        excalidrawAPIRef.current.updateScene({ appState: { isLoading: true } });

        initializeScene({
          collabAPI,
          excalidrawAPI:excalidrawAPIRef.current,
          viewMode,
          tabId: currentTab.tabId,
        }).then((data) => {
          loadImages(data);
          if (data.scene) {
            excalidrawAPIRef.current?.updateScene({
              ...data.scene,
              ...restore(data.scene, null, null, { repairBindings: true }),
              storeAction: StoreAction.CAPTURE,
            });
          }
        });
      }
    };

    // window.addEventListener(EVENT.HASHCHANGE, onHashChange, false);

    // if (currentSession.presenter == "true") {
      excalidrawAPIRef.current!.onScrollChange(() => {
        collabAPI?.relayVisibleSceneBounds();
      });
    // }

    return () => {
      window.removeEventListener(EVENT.HASHCHANGE, onHashChange, false);
    };
  }, [currentTab, excalidrawAPIRef.current, collabAPI]);

  useHandleLibrary({
    excalidrawAPI:excalidrawAPIRef.current,
    adapter: LibraryIndexedDBAdapter,
    // TODO maybe remove this in several months (shipped: 24-03-11)
    migrationAdapter: LibraryLocalStorageMigrationAdapter,
  });

  const onChange = (
    elements: readonly OrderedExcalidrawElement[],
    appState: AppState,
    files: BinaryFiles,
  ) => {
    if (collabAPI) {
      // console.log("syncing elements...", elements);
      collabAPI.syncElements(currentTab?.tabId!, elements);
    }
  };

  const onTabCreate = () => {
    const tabMeta: Tab = {
      tabId: randomId(),
      type: "normal",
      title: "New Tab",
    };

    syncData.createTab(
      currentSession?.roomId!,
      currentSession?.me.userId!,
      tabMeta,
    );
    // collabAPI?.createTab(tabMeta);
    onTabSelect(tabMeta);
  };

  const savePreviousTab = () => {
    if (excalidrawAPIRef.current) {
      const previousTabId = previousTab?.tabId;
      console.log("previous tab id...", previousTabId);
      if (previousTabId && previousTabId != currentTab?.tabId) {
        const localElements = excalidrawAPIRef.current.getSceneElements();
        const currentElements = restoreElements(localElements, null);
        // const previoudTab = tabPoints.get(previousTabId);
        if (currentElements) {
          tabPoints.set(previousTabId, currentElements);
          // Save the current tab's elements
        }
      }
    }
  };

  const onTabClose = (tab: Tab) => {
    removeTab(tab.tabId);

    syncData.deleteTab(
      currentSession?.roomId!,
      currentSession?.me.userId!,
      tab,
    );
    // collabAPI?.deleteTab(tab);
  };

  const onTabSelect = (tab: Tab) => {
    syncData.switchTab(
      currentSession?.roomId!,
      currentSession?.me.userId!,
      tab,
    );
  };

  const onExportToBackend = async (
    exportedElements: readonly NonDeletedExcalidrawElement[],
    appState: Partial<AppState>,
    files: BinaryFiles,
  ) => {
    if (exportedElements.length === 0) {
      throw new Error(t("alerts.cannotExportEmptyCanvas"));
    }
    try {
      const { url, errorMessage } = await exportToBackend(
        exportedElements,
        {
          ...appState,
          viewBackgroundColor: appState.exportBackground
            ? appState.viewBackgroundColor
            : getDefaultAppState().viewBackgroundColor,
        },
        files,
      );

      if (errorMessage) {
        throw new Error(errorMessage);
      }

      // if (url) {
      //   setLatestShareableLink(url);
      // }
    } catch (error: any) {
      if (error.name !== "AbortError") {
        const { width, height } = appState;
        console.error(error, {
          width,
          height,
          devicePixelRatio: window.devicePixelRatio,
        });
        throw new Error(error.message);
      }
    }
  };

  useEffect(() => {
    let counter = 0;
    if (whiteboardFile.fileId) {
      console.log("Current file is", whiteboardFile.fileId);
      let interval = setInterval(() => {
        fetchConvertedFiles(whiteboardFile.fileId).then(async (res) => {

          
          if (counter > 10) {
            clearInterval(interval);
            enqueueSnackbar("File Conversion is taking too long. Please try again later.", {
              variant: "error",
            });
          }
          counter++;
          if (res.status === "completed") {
            clearInterval(interval);

            if(res.type == "image") {
              openImage(res)
            } else if (res.type == "pdf") {
              openPdf(res);
            } else if (res.type == "ppt") {
              openPdf(res);
            } else {
              console.log("Unknown file type", res);
              alert("Will be implemented soon");
            }

          } else {
            enqueueSnackbar("File Conversion is in progress.", {
              variant: "warning",
            });
          }
        });
      }, 1000);
      
    }
  }, [whiteboardFile]);


  const openImage = (ress:{ fileId: any; rootUrl: string; urls: any; resolution:string }) => {
    setTimeout(async () => {
      const fileId = ress.fileId;
      // const ress = await fetchConvertedFiles(fileId);
      console.log("Response from image files", ress);

      // const resp = await fetch(ress.rootUrl + ress.url);
      // const res = await resp.json();
      console.log(" these images", ress.urls);

      let currentY = 0; // Start Y position for placing images

      // Helper function to process images sequentially
      const processImage = async (
        file: string,
        height: number,
        width: number,
      ) => {
        const url = ress.rootUrl + "/" + file;
        // console.log(`Loading image ${index + 1}: ${url}`);
      
        let attempt = 0; // Track the number of attempts
        const maxAttempts = 3; // Maximum number of attempts
        let success = false; // Flag to track if loading was successful
        let result;
      
        while (attempt < maxAttempts && !success) {
          try {

            console.log("excalidraw version ::: ",excalidrawAPIRef.current?.id);
            result = await fetchFileWithElm(
              url,
              fileId,
              collabAPI?.getLastBroadcastedOrReceivedSceneVersion() ?? 0,
              height,
              width,
              excalidrawAPIRef.current?.getAppState().width ?? 0,
              excalidrawAPIRef.current?.getAppState().height ?? 0,
              currentY, // Pass the current Y position
              false,
            );
      
            if (result && excalidrawAPIRef.current) {
              excalidrawAPIRef.current.addFiles([result.image]);
      
              const elements = excalidrawAPIRef.current
                .getSceneElementsIncludingDeleted()
                .slice(0);
              const hasElm = elements.filter((elm) => elm.id === result.elm.id);
      
              if (!hasElm.length) {
                elements.push(result.elm);
                // console.log(`Adding image ${index + 1} to the scene.`);
              }
      
              excalidrawAPIRef.current.updateScene({
                elements,
                appState:{
                  selectedElementIds: {
                    [result.elm.id]: true
                  }
                }
              });
      
              // Update Y position only after the image is added
              currentY += height + 10;
              success = true; // Set success to true if image loads correctly
            } else {
              throw new Error(`Result is null or excalidrawAPI is undefined for image .`);
            }
          } catch (error) {
            attempt++;
            console.error(`Failed to load image (attempt ${attempt}): ${error.message}`);
            if (attempt < maxAttempts) {
              console.log(`Retrying image ...`);
            }
          }
        }
      
        if (!success) {
          console.error(`Failed to load image after ${maxAttempts} attempts.`);
        }
      };
      

      // Load and process each image in order
      let height = 0;
      let width = 0;

      if (ress.resolution) {
        const sizes = ress.resolution.split("x");
        width = parseInt(sizes[0]);
        height = parseInt(sizes[1]);
      }

      // for (let index = 0; index < ress.urls.length; index++) {
        await processImage(ress.urls[0], height, width);
      // }

      console.log("All images are loaded and added to the scene.");
    }, 500);
  };

  const openPdf = (ress: { fileId: any; rootUrl: string; urls: any; resolution:string }) => {
    console.log("Starting to open PDF...");

    const tab: Tab = {
      tabId: randomId(),
      type: "file",
      title: "File tab",
      url: ress.fileId,
    };

    syncData.createTab(
      currentSession?.roomId!,
      currentSession?.me.userId!,
      tab,
    );

    syncData.switchTab(
      currentSession?.roomId!,
      currentSession?.me.userId!,
      tab,
    );

    setTimeout(async () => {
      const fileId = ress.fileId;
      // const ress = await fetchConvertedFiles(fileId);
      console.log("Response from converted files", ress);

      // const resp = await fetch(ress.rootUrl + ress.url);
      // const res = await resp.json();
      console.log("PDF contains these images", ress.urls);

      let currentY = 0; // Start Y position for placing images

      // Helper function to process images sequentially
      const processImage = async (
        file: string,
        height: number,
        width: number,
        index: number,
      ) => {
        const url = ress.rootUrl + "/" + file;
        console.log(`Loading image ${index + 1}: ${url}`);
      
        let attempt = 0; // Track the number of attempts
        const maxAttempts = 3; // Maximum number of attempts
        let success = false; // Flag to track if loading was successful
        let result;
      
        while (attempt < maxAttempts && !success) {
          try {

            console.log("excalidraw version ::: ",excalidrawAPIRef.current?.id);
            result = await fetchFileWithElm(
              url,
              fileId + "_file_" + index,
              collabAPI?.getLastBroadcastedOrReceivedSceneVersion() ?? 0 + index,
              height,
              width,
              excalidrawAPIRef.current?.getAppState().width ?? 0,
              excalidrawAPIRef.current?.getAppState().height ?? 0,
              currentY, // Pass the current Y position
              true
            );
      
            if (result && excalidrawAPIRef.current) {
              excalidrawAPIRef.current.addFiles([result.image]);
      
              const elements = excalidrawAPIRef.current
                .getSceneElementsIncludingDeleted()
                .slice(0);
              const hasElm = elements.filter((elm) => elm.id === result.elm.id);
      
              if (!hasElm.length) {
                elements.push(result.elm);
                console.log(`Adding image ${index + 1} to the scene.`);
              }
      
              excalidrawAPIRef.current.updateScene({
                elements
              });

      
              // Update Y position only after the image is added
              currentY += height + 10;
              success = true; // Set success to true if image loads correctly
            } else {
              throw new Error(`Result is null or excalidrawAPI is undefined for image ${index + 1}.`);
            }
          } catch (error) {
            attempt++;
            console.error(`Failed to load image ${index + 1} (attempt ${attempt}): ${error.message}`);
            if (attempt < maxAttempts) {
              console.log(`Retrying image ${index + 1}...`);
            }
          }
        }
      
        if (!success) {
          console.error(`Failed to load image ${index + 1} after ${maxAttempts} attempts.`);
        }
      };
      

      // Load and process each image in order
      let height = 0;
      let width = 0;

      if (ress.resolution) {
        const sizes = ress.resolution.split("x");
        width = parseInt(sizes[0]);
        height = parseInt(sizes[1]);
      }

      for (let index = 0; index < ress.urls.length; index++) {
        await processImage(ress.urls[index], height, width, index);
      }

      console.log("All images are loaded and added to the scene.");
    }, 500);
  };


  const renderContent = () => {
    switch (currentTab?.type) {
      case "normal":
      case "file":
        // console.log("rendering again excalid da... view mode..", viewMode);
        return (
          <Excalidraw
            excalidrawAPI={excalidrawRefCallback}
            onChange={onChange}
            initialData={initialStatePromiseRef.current.promise}
            isCollaborating={true}
            onPointerUpdate={collabAPI?.onPointerUpdate}
            viewModeEnabled={viewMode}
            UIOptions={{
              canvasActions: {
                toggleTheme: true,
              },
            }}
            langCode={langCode}
            detectScroll={false}
            handleKeyboardGlobally={true}
            autoFocus={true}
            theme={editorTheme}
            // renderTopRightUI={renderTopRightUI}
          >
            <AppMainMenu
              // theme={appTheme}
              // setTheme={(theme) => setAppTheme(theme)}
            />
            {/* <AppWelcomeScreen /> */}

            {excalidrawAPIRef.current && <AIComponents excalidrawAPI={excalidrawAPIRef.current} />}

            <TTDDialogTrigger />

            {errorMessage && (
              <ErrorDialog onClose={() => setErrorMessage("")}>
                {errorMessage}
              </ErrorDialog>
            )}
          </Excalidraw>
        );

      case "screen":
        if (screenVideoTrack) {
          return (
            <div className="screen-share-container">
              <video
                autoPlay
                playsInline
                ref={(videoElement) => {
                  if (videoElement) {
                    screenVideoTrack.videoTrack?.attach(videoElement);
                  }
                }}
              />
            </div>
          );
        }
        return (
          <div className="placeholder-container">
            <p className="text-center text-gray-500">Screen share</p>
          </div>
        );

      case "external-video":
        if (externalVideoSrc) {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                height: "100%",
                alignItems: "center",
              }}
            >
              <ExternalVideoPlayer
                action={currentExternalMediaPlayer.action}
                seekTo={currentExternalMediaPlayer.seekTo}
                tabId={currentTab.tabId}
                src={externalVideoSrc}
              />
            </div>
          );
        }

      case "webpage":
        if (externalWebpage) {
          return <ExternalWebpage src={externalWebpage} />;
        }

        return (
          <div className="placeholder-container">
            <p className="text-center text-gray-500">Loading external src</p>
          </div>
        );

      default:
        return (
          <div className="placeholder-container">
            <p className="text-center text-gray-500">No boards. Click + at to top right to create new board.</p>
          </div>
        );
    }
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div
        style={{ height: "38px", borderBottom:"1px solid var(--dialog-border-color)" }}
      >
        <TabHeader
          onTabCreate={onTabCreate}
          onTabSelect={onTabSelect}
          onTabClose={onTabClose}
        />
      </div>
      <div style={{ flex: "1 1 auto", overflow: "auto" }}>
        {renderContent()}
      </div>
    </div>
  );
};

const ExcalidrawApp = () => {
  return <ExcalidrawWrapper />;
};

export default ExcalidrawApp;
