import type {
  MqttClient,
  IClientOptions,
  IClientPublishOptions,
  IClientSubscribeOptions,
} from "mqtt";
import mqtt from "mqtt";
import { enqueueSnackbar } from "notistack";

class MqttService {
  private client: MqttClient | null = null;
  private connectingPromise: Promise<void> | null = null;
  private clientId: string = "";
  private isInitialConnect = true;

  constructor(
    clientid: string,

    private url: string = "wss://live1.meritgraph.com:443/mqtt",
  ) {
    this.clientId = `${clientid}+${Date.now().toString()}`;
  }

  private disconnected: boolean = false;

  /**
   * Connects to the MQTT broker.
   * @param options - MQTT client options.
   * @param onConnect - Callback when connected.
   * @param onError - Callback when an error occurs.
   */
  public connect(
    options: IClientOptions,
    onConnect: () => void,
    onError: (error: Error) => void,
  ): Promise<void> {
    if (this.client?.connected) {
      console.log("Already connected to MQTT");
      return Promise.resolve();
    }

    if (this.connectingPromise) {
      console.log("Connection already in progress. Waiting for it to finish.");
      return this.connectingPromise;
    }

    this.connectingPromise = new Promise<void>((resolve, reject) => {
      console.log("connecting mqtt with clienId: ", this.clientId);
      this.client = mqtt.connect(this.url, {
        ...options,
        username: "localhost",
        reconnectPeriod: 10000,
        clientId: this.clientId,
        clean: false,
        keepalive:30,
      });

      this.client.on("reconnect", () => {
        console.log("mqtt reconnecting... ");
        if (this.disconnected) {
        }
        enqueueSnackbar("Mqtt reconnecting", { variant: "warning" });
      });


      this.client.on("error", (error) => {
        console.error("MQTT error:", error);
      });

      this.client.on("offline", () => {
        console.warn("MQTT client went offline");
      });

      this.client.on("close", () => {
        console.warn("MQTT connection closed");
      });

      this.client.on("end", () => {
        console.warn("MQTT client ended");
      });

      this.client.on("disconnect", (packet) => {
        console.log("mqtt disconnected::: ", packet);
        enqueueSnackbar("Mqtt disconnected", { variant: "error" });
        this.disconnected = true;
      });

      this.client.on("connect", () => {
        console.log("Connected to MQTT broker");

        if (this.isInitialConnect) {
          onConnect();
          this.isInitialConnect = false;
        }

        resolve(); // Resolve the promise when connected
        this.connectingPromise = null; // Reset connecting promise

        enqueueSnackbar("Mqtt connected successfully", { variant: "success" });
      });

      this.client.on("error", (error) => {
        console.error("MQTT connection error:", error);
        onError(error);
        reject(error); // Reject the promise on error
        this.connectingPromise = null; // Reset connecting promise
        enqueueSnackbar("Mqtt connection error", {
          variant: "error",
        });
      });
    });

    return this.connectingPromise;
  }

  /**
   * Subscribes to a given topic or topics.
   * @param topics - Topic or list of topics to subscribe to.
   * @param options - Optional subscription options.
   */
  public subscribe(
    topics: string | string[],
    options?: IClientSubscribeOptions,
  ) {
    if (this.client) {
      this.client.subscribe(topics, options, (err) => {
        if (err) {
          console.error("Subscription error:", err);
        } else {
          console.log(`Subscribed to topic(s): ${topics}`);
        }
      });
    } else {
      console.warn("MQTT client not connected");
    }
  }

  /**
   * Publishes a message to a given topic.
   * @param topic - Topic to publish the message to.
   * @param message - Message to publish.
   * @param options - Optional publish options.
   */
  public publish(
    topic: string,
    message: string,
    options?: IClientPublishOptions,
  ) {
    if (this.client) {
      this.client.publish(topic, message, options, (err) => {
        if (err) {
          console.error("Publish error:", err);
        } else {
          // console.log(`Message published to ${topic}: ${message}`);
        }
      });
    } else {
      console.warn("MQTT client not connected");
    }
  }

  /**
   * Sets a handler for incoming messages on the subscribed topics.
   * @param handler - Function to handle incoming messages.
   */
  public setMessageHandler(handler: (topic: string, message: Buffer) => void) {
    // console.log("message handler set for topic: ",topic);

    if (this.client) {
      this.client.on("message", handler);
    } else {
      console.warn("MQTT client not connected");
    }
  }

  /**
   * Returns the current MQTT client instance.
   * @returns The current MQTT client instance or null if not connected.
   */
  public getClient(): MqttClient | null {
    return this.client;
  }

  /**
   * Disconnects from the MQTT broker and cleans up resources.
   */
  public disconnect() {
    if (this.client) {
      this.client.end();
      console.log("Disconnected from MQTT broker");
    } else {
      console.warn("MQTT client not connected");
    }
  }

  public static getWhiteboardTopic(roomId: string): string {
    return `orgname/whiteboard/${roomId}`;
  }

  public static getClassroomTopic(roomId: string): string {
    return `orgname/classroom/${roomId}`;
  }
}

let currentMqtt: MqttService | null = null;

export const createMqttConnection = (clientId: string, url: string) => {
  if (!currentMqtt) {
    currentMqtt = new MqttService(clientId, url);
  }
  return currentMqtt;
};

export const getMqttConn = (): MqttService => {
  if (!currentMqtt) {
    throw new Error("Mqtt connection not created yet.");
  }
  return currentMqtt;
};

export default MqttService;
