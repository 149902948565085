import { atom } from "jotai";
import { appJotaiStore } from "../app-jotai";
import { Participant } from "../types/livekit";
import { currentSessionAtom } from "./session";

export const participantListAtom = atom<Map<string, Participant>>(new Map());
export const participantLoadingAtom = atom<boolean>(false);
export const isParticipantListVisibleAtom = atom(false);

export const addParticipant = (participant: Participant) => {
  console.log("user added... is online", participant);
  appJotaiStore.set(participantListAtom, (prev) => {
    const newMap = new Map(prev);
    newMap.set(participant.userId, participant);
    return newMap;
  });
};

export const addParticipants = (participants: Participant[]) => {
  appJotaiStore.set(participantListAtom, (prev) => {
    const newMap = new Map(prev);
    participants.forEach((participant) => {
      // if(participant.isOnline) {
        newMap.set(participant.userId, participant);
      // }
    });
    return newMap;
  });
};

export const updateParticipant = (
  id: string,
  changes: Partial<Participant>,
): Participant | undefined => {
  // Update the participant list first
  appJotaiStore.set(participantListAtom, (prev) => {
    const newMap = new Map(prev);
    const participant = newMap.get(id);
    if (participant) {
      newMap.set(id, { ...participant, ...changes });
    }
    return newMap;
  });

  const updatedParticipant = getParticipant(id);

  // Check if the updated participant is the current session user
  const currentSession = appJotaiStore.get(currentSessionAtom);

  if (currentSession && currentSession.me.userId === id && updatedParticipant) {
    // Update the user in the current session if it's the same participant
    console.log("participant changed... currentSesion changing....")
    appJotaiStore.set(currentSessionAtom, {
      ...currentSession,
      me: { ...currentSession.me, ...changes }, // Merge changes into the current session user
    });
  }

  return updatedParticipant;
};

export const removeParticipant = (id: string) => {
  appJotaiStore.set(participantListAtom, (prev) => {
    const newMap = new Map(prev);
    newMap.delete(id);
    return newMap;
  });
};

export const getParticipant = (userId: string) => {
  return appJotaiStore.get(participantListAtom)?.get(userId);
};
